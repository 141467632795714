<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->
<!-- eslint-disable vue/no-v-html -->

<template>
    <div class="sl-article-grid__item sl-article-grid__item--group">
        <!-- Product photo -->
        <img
            v-if="article.photos?.length"
            :src="article.photos[0]"
            alt=""
            class="sl-article-grid__illustration"
        />

        <div v-else class="sl-article-grid__illustration sl-article-grid__illustration--none">
            <img src="/img/camera-off.svg" alt="" />
        </div>

        <!-- Badges for filters -->
        <div v-if="filterBadges.length" class="sl-article-grid__filters">
            <div
                v-for="filterBadge in filterBadges"
                :key="filterBadge.tag"
                class="sl-article-grid__filter"
                :class="filterBadge.class"
            >
                {{ filterBadge.tag }}
            </div>
        </div>

        <!-- Badges for package quantity -->
        <div v-if="badges.length" class="sl-article-grid__badges">
            <div
                v-for="(badge, index) in badges"
                :key="index"
                class="sl-article-grid__badge"
            >
                {{ badge }}
            </div>
        </div>

        <!-- Availability Communication -->
        <div class="sl-article-grid__availability-communication">
            <template v-if="article.extra.availability_communication">
                {{ article.extra.availability_communication }}
            </template>
        </div>

        <!-- General Communication -->
        <div class="sl-article-grid__general-communication">
            <template v-if="article.extra.general_communication">
                {{ article.extra.general_communication }}
            </template>
        </div>

        <!-- Price -->
        <div
            class="flex justify-between items-center mb-2 w-full"
            :class="{ 'blur': !pricesVisible }"
        >
            <!-- Total Price -->
            <div class="sl-article-grid__total-price">
                {{ getFormattedTotalPrice(article) }}
            </div>

            <!-- Unit Price -->
            <div v-if="!hideUnitPrice" class="sl-article-grid__unit-price">
                {{ getFormattedUnitPrice(article) }}
            </div>
        </div>

        <!-- Footer -->
        <div class="sl-article-grid__footer">
            <div class="sl-article-grid__footer-1">
                <!-- Label -->
                <div class="sl-article-grid__label">
                    <select v-model="current" class="sl-article-grid__selector">
                        <option
                            v-for="(item, id) in group.articles"
                            :key="item.id"
                            :value="id"
                        >
                            {{ item.label }}
                        </option>
                    </select>
                </div>

                <!-- Description (if any) -->
                <tippy>
                    <mdi-icon
                        v-if="article.description"
                        icon="mdiInformationOutline"
                        class="cursor-help ml-1 w-4 h-4 text-gray-500"
                    />

                    <template #content>
                        <div
                            class="sl-article-grid__description"
                            v-html="nl2br(article.description)"
                        >
                        </div>
                    </template>
                </tippy>

                <div class="grow"></div>

                <!-- "Add to Basket" button -->
                <div class="sl-article-grid__actions">
                    <div class="relative">
                        <div
                            v-if="basketQuantity"
                            class="sl-article-grid__basket-quantity"
                        >
                            {{ basketQuantity }}
                        </div>

                        <tippy>
                            <mdi-icon
                                icon="mdiBasket"
                                class="sl-article-grid__basket"
                                :class="{
                                    'sl-article-grid__basket--loading': addingToBasket,
                                    'sl-article-grid__basket--disabled': basketDisabled,
                                }"
                                @click.stop="addToBasket(article)"
                            />

                            <template #content>
                                <template v-if="basketDisabled">
                                    {{ article.extra.availability_communication }}
                                </template>

                                <template v-else>
                                    {{ $t('common.add_to_basket') }}
                                </template>
                            </template>
                        </tippy>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import { piecesTagRegex } from '@/shared/ArticlesGridSharedObjects';

export default
{
    name: 'GroupedArticleGridItem',

    props:
    {
        group:
        {
            type:     Object,
            required: true,
        },

        filterBadges:
        {
            type:    Array,
            default: () => [],
        },

        badges:
        {
            type:    Array,
            default: () => [],
        },

        hideUnitPrice:
        {
            type:    Boolean,
            default: false,
        },
    },

    data()
    {
        return {
            current:        0,
            addingToBasket: false,
        };
    },

    computed:
    {
        /**
         * Disable adding to basket if there's an availability communication.
         */
        basketDisabled()
        {
            // Never disable basket for SL staff
            if(this.$store.getters['account/isStaff'])
            {
                return false;
            }

            return !!this.article.extra.availability_communication;
        },

        basketQuantity()
        {
            const basketLines = this.$store.state.basket.lines.filter(l =>
            {
                const a = this.$store.getters['catalog/getByCode'](l.article_code);
                return a?.extra.display_group === this.group.id;
            });

            return basketLines.reduce((acc, l) => acc + l.quantity, 0);
        },

        pricesVisible()
        {
            return this.$store.state.pricesVisible;
        },

        article()
        {
            if(this.current < 0 || this.current > this.group.articles.length)
            {
                return this.group.articles[0];
            }

            return this.group.articles[this.current];
        },
    },

    methods:
    {
        getPhotoSrc(article)
        {
            return article.photos?.length
                ? article.photos[0]
                : '/img/camera-off.svg';
        },

        getFormattedUnitPrice(article)
        {
            const units = this.getUnitsFromTags(article);
            const unitPrice = parseFloat(article.base_price / units).toFixed(2);
            const formattedPrice = article.currency_code + ' ' +
                this.$f(unitPrice, 'thousand');

            return this.$t('cleaning_products.price_per_unit', {
                price: formattedPrice,
            });
        },

        getFormattedTotalPrice(article)
        {
            const price = parseFloat(article.base_price).toFixed(2);

            return article.currency_code + ' ' + this.$f(price, 'thousand');
        },

        /**
         * Retrieve how many units are sold as one article.
         */
        getUnitsFromTags(article)
        {
            const tag = article.tags.find(t => piecesTagRegex.test(t.code));

            return parseInt(tag?.code.replace(piecesTagRegex, '$1')) || 1;
        },

        /**
         * Replace new lines with `<br>` tags in a string.
         *
         * @param {string} str
         * @returns {string}
         */
        nl2br(str)
        {
            return `${str}`.replace(/\r\n|\r|\n/g, '<br>');
        },

        addToBasket(article)
        {
            if(this.basketDisabled)
            {
                return;
            }

            // Only add one piece at a time
            if(this.addingToBasket)
            {
                return;
            }

            this.addingToBasket = true;

            const basketLine = {
                article_code: article.code,
                quantity:     1,
                data:         null,
            };
            this.$store.dispatch('basket/saveLines', basketLine)
                .then(lines =>
                {
                    // nothing
                })
                .catch(error =>
                {
                    // TODO: Display error
                })
                .then(() =>
                {
                    this.addingToBasket = false;
                });
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
</style>
