<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-app-layout">
        <!-- Global Overlay -->
        <div v-if="showOverlay" class="fixed inset-0 z-70 flex justify-center items-center bg-gray-500/75 backdrop-blur-sm select-none">
            <div class="text-center text-xl tracking-wide text-gray-800">
                {{ overlayText }}
            </div>
        </div>

        <!-- Navbar -->
        <div class="sl-navbar">
            <div class="container mx-auto max-w-screen-xl h-full flex">
                <!-- Main Menu -->
                <main-menu-burger />

                <!-- Home (SwissLens Logo) - only visible when screen > 1200px -->
                <router-link :to="{ name: 'home', params: $route.params }" class="sl-logo__wrapper">
                    <img class="sl-logo" src="/img/logo-swisslens-white.svg">
                </router-link>

                <!-- Main Menu -->
                <main-menu />

                <div class="sl-navbar__icon-list justify-self-end">

                    <!-- Orders Waiting for Review -->
                    <template v-if="waitingForReview.length">
                        <div class="sl-navbar__icon-item" @click="waitingForReviewIsOpen = !waitingForReviewIsOpen">
                            <mdi-icon icon="mdiAlertBox" />
                            <div v-if="hasUrgentWaitingForReview" class="sl-navbar__icon-warning sl-navbar__icon-warning--pulse"></div>
                            <div v-else class="sl-navbar__icon-dot bg-red-600"></div>
                        </div>

                        <div class="sl-navbar__separator"></div>
                    </template>

                    <!-- Internal Note on Entity -->
                    <template v-if="$store.getters['account/isStaff']">
                        <div class="sl-navbar__icon-item" @click="$store.dispatch('account/toggleEntityInternalNotePanel')">
                            <mdi-icon icon="mdiCardAccountDetailsOutline" />

                            <div v-if="!entityInternalNoteIsEmpty" class="sl-navbar__icon-dot bg-yellow-400"></div>
                        </div>

                        <div class="sl-navbar__separator"></div>
                    </template>

                    <!-- Basket -->
                    <basket-menu />

                    <div class="sl-navbar__separator"></div>

                    <!-- Prices Visibility Toggle -->
                    <prices-visibility-menu />

                    <!-- <div class="sl-navbar__separator"></div> -->

                    <!-- Notifications Menu -->
                    <!-- <notification-menu /> -->

                    <div class="sl-navbar__separator"></div>

                    <!-- Languages Menu -->
                    <languages-menu />

                    <div class="sl-navbar__separator"></div>

                    <!-- Account & Drop-Down Menu -->
                    <dropdown-menu />

                    <!-- Entities Switch Button (only if current user has access to more than one) -->
                    <template v-if="entitiesCount > 1">
                        <div class="sl-navbar__separator"></div>
                        <div class="sl-navbar__icon-item sl-dropdown-menu" @click="goToEntitiesSwitch">
                            <mdi-icon icon="mdiAccountSwitch" />
                        </div>
                    </template>
                </div>
            </div>

            <!-- Arrow/Dent pointing to the current main menu item -->
            <div class="sl-navbar__dent" :style="dentStyle"></div>
        </div>

        <!-- App Content -->
        <div class="sl-container container">
            <!-- Secondary Menu -->
            <secondary-menu />

            <!-- Notification Band -->
            <div class="sl-notifications-band">
                <transition-group name="fade">
                    <div
                        v-for="(notification, index) in $store.state.notifications.band"
                        :key="notification"
                        class="sl-notification"
                        :class="{ 'sl-notification--dismissible': notification.dismissible, ['sl-notification--' + notification.theme]: true }"
                    >
                        <mdi-icon
                            v-if="notification.dismissible"
                            :key="index"
                            icon="mdiClose"
                            class="sl-notification__dismiss"
                            @click="dismissNotification(notification.id)"
                        />

                        <div class="sl-notification__title">
                            {{ notification.title }}
                        </div>

                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <div class="sl-notification__text" v-html="formatNotificationText(notification.text)"></div>
                    </div>
                </transition-group>
            </div>

            <!-- Current view will come here -->
            <router-view v-slot="{ Component }">
                <transition name="fast-fade" mode="out-in">
                    <component :is="Component" />
                </transition>
            </router-view>

            <!-- Footer -->
            <sl-footer />
        </div>

        <orders-waiting-for-review
            v-if="$store.state.orders.waitingForReview.length"
            :is-open="waitingForReviewIsOpen"
            @close="waitingForReviewIsOpen = false"
        />

        <entity-internal-note-panel
            :is-open="$store.state.account.entityInternalNotePanelIsOpen"
            @close="$store.dispatch('account/toggleEntityInternalNotePanel', false)"
        />
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import BasketMenu              from './BasketMenu.vue';
import DropdownMenu            from './DropdownMenu.vue';
import SlFooter                from './Footer.vue';
import LanguagesMenu           from './LanguagesMenu.vue';
import MainMenu                from './MainMenu.vue';
import MainMenuBurger          from './MainMenuBurger.vue';
import PricesVisibilityMenu    from './PricesVisibilityMenu.vue';
import NotificationMenu        from './NotificationMenu.vue';
import SearchBox               from './SearchBox.vue';
import SecondaryMenu           from './SecondaryMenu.vue';
import OrdersWaitingForReview  from '@/components/order/OrdersWaitingForReview.vue';
import EntityInternalNotePanel from '@/components/order/EntityInternalNotePanel.vue';
import MdiIcon                 from '@/components/MdiIcon.vue';
import moment                  from 'moment';
import { has }                 from 'lodash-es';

export default
{
    name: 'AppLayout',

    components:
    {
        BasketMenu,
        DropdownMenu,
        SlFooter,
        LanguagesMenu,
        MainMenu,
        MainMenuBurger,
        PricesVisibilityMenu,
        NotificationMenu,
        SecondaryMenu,
        SearchBox,
        OrdersWaitingForReview,
        EntityInternalNotePanel,
        MdiIcon,
    },

    data()
    {
        return {
            waitingForReviewIsOpen: false,
        };
    },

    computed:
    {
        initializationStatus()
        {
            return this.$store.state.initializationStatus;
        },

        entitiesCount()
        {
            return this.$store.state.account.entitiesCount;
        },

        showOverlay()
        {
            return this.$store.state.overlay.show;
        },

        overlayText()
        {
            return this.$store.state.overlay.text;
        },

        dentStyle()
        {
            const out = {
                left: '-10px',
            };

            const dimensions = this.$store.state.cMainMenuElement?.$el.getBoundingClientRect();
            if(!dimensions || dimensions.left <= 0 || dimensions.width <= 0)
            {
                return out;
            }

            out.left = dimensions.left + (dimensions.width / 2) - 5; // align the dent to the middle of the current menu item
            out.left += 'px';

            return out;
        },

        entityInternalNoteIsEmpty()
        {
            const div = document.createElement('div');
            div.innerHTML = this.$store.state.account.cEntity.internal_note || '';

            return div.textContent.trim().length === 0;
        },

        waitingForReview()
        {
            return this.$store.state.orders.waitingForReview;
        },

        hasUrgentWaitingForReview()
        {
            let hasUrgentWaitingForReview = false;

            // Loop trhough waiting for review orders
            for(let order of this.waitingForReview)
            {
                // If current order is urgent
                if(has(order, 'logistics_data.URGENT') && order.logistics_data.URGENT)
                {
                    let orderDate = moment(order.odate, 'YYYY-MM-DD hh:mm:ss');
                    let todayAt10am = moment().hours(10).minutes(0).seconds(0);

                    // If current order was made before today 10:00 am
                    if(orderDate.isBefore(todayAt10am))
                    {
                        hasUrgentWaitingForReview = true;
                        break;
                    }
                }
            }

            return hasUrgentWaitingForReview;
        },
    },

    created()
    {
        if(this.$store.getters['account/isStaff'])
        {
            this.$store.dispatch('account/fetchEntityInternalNote');
        }

        // Start to fetch orders waiting for review if user is an interval staff member.
        if(this.$store.getters['account/isInternalStaff'])
        {
            this.$store.dispatch('orders/startWaitingForReviewRefreshInterval');
        }
    },

    methods:
    {
        goToEntitiesSwitch()
        {
            this.$store.dispatch('onBeforeEntitySwitch');
            this.$router.push({ name: 'entities-switch', params: this.$route.params });
        },

        formatNotificationText(text)
        {
            return text.replace(/(\r\n|\r|\n)/g, '<br>') // new lines
                .replace( // urls
                    /((?:https?:\/{2})(?:[a-z0-9]{2,}\.?){2,}(?:\/[a-z0-9\-]*)*)/gmi,
                    '<a href="$1" target="_blank" class="sl-notification__link">$1</a>'
                );
        },

        dismissNotification(id)
        {
            this.$store.dispatch("notifications/removeBandNotification", id, { root: true });
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">
/*--- logo ---*/

.sl-logo__wrapper
{
    width: 155px;
    @apply flex items-center justify-start;
}

.sl-logo
{
    height: 20px;
}

/*--- vertical separator ---*/

.sl-navbar__separator
{
    height: 20px;
    @apply align-top inline-block text-center border-l border-primary-200;
}

/*--- icons ---*/

.sl-navbar__icon-list
{
    margin-left: auto;
    width: 255px;
    @apply h-full flex items-center justify-end;
}

.sl-navbar__icon-item
{
    width: 50px;
    @apply relative inline-block cursor-pointer h-full text-center;
}

.sl-navbar__icon-item .mdi-icon
{
    @apply m-[13px] w-6 h-6 text-white;
}

.sl-navbar__icon-dot
{
    @apply absolute top-2 right-2 w-3 h-3 rounded-full;
}

.sl-navbar__icon-warning
{
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 8px 15px 8px;
    border-color: transparent transparent #FF4532 transparent;
    transform: rotate(0deg);
    @apply absolute top-2 right-2;
}

.sl-navbar__icon-warning--pulse
{
    animation: pulse-animation 2s infinite;
}

.sl-navbar__icon-warning::after
{
    content: '!';
    margin-left: -1.5px;
    font-size: 0.65rem;
    @apply text-white text-center;
}

@keyframes pulse-animation
{
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.4);
  }
  100% {
    box-shadow: 0 0 0 40px rgba(0, 0, 0, 0);
  }
}

/*--- Navbar dent ---*/
.sl-navbar__dent
{
    position: fixed;
    // `left` is set dynamically to the offset of the current main menu item (from $route.meta)
    top: 30px;
    display: block;
    width: 20px;
    height: 20px;
    border: 10px solid transparent;
    border-bottom-color: theme('colors.gray.100');
}

/*--- Notifications band ---*/
.sl-notifications-band
{
    @apply sticky top-0;

    // Fade animation (for dismissal)
    .fade-enter-active,
    .fade-leave-active
    {
        transition: opacity 1s ease;
    }

    .fade-enter-from,
    .fade-leave-to
    {
        @apply opacity-0;
    }

    // A notification
    > .sl-notification
    {
        @apply relative p-2 text-sm text-center;

        // The notification's "dismiss" icon/button
        > .sl-notification__dismiss
        {
            @apply cursor-pointer absolute top-2 right-2 w-4 h-4;
        }

        // The notification's title
        > .sl-notification__title
        {
            @apply font-bold;
        }

        // Any link in the notification's text
        > .sl-notification__text
        {
            .sl-notification__link
            {
                @apply text-primary-600 hover:underline;
            }
        }


        /*--- Color themes ---*/

        &.sl-notification--info
        {
            @apply bg-primary-100 text-primary-600;
        }

        &.sl-notification--warn
        {
            @apply bg-yellow-100 text-yellow-700;
        }

        &.sl-notification--danger
        {
            @apply bg-red-100 text-red-700;
        }

        &.sl-notification--success
        {
            @apply bg-green-100 text-green-700;
        }

        &.sl-notification--disabled
        {
            @apply bg-gray-100 text-gray-700;
        }
    }
}
</style>
