<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-navbar__icon-item">
        <mdi-icon icon="mdiBellOutline" />
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>

export default
{
    name: 'NotificationMenu',
};

</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
</style>
