<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->
<!-- eslint-disable vue/no-v-html -->

<template>
    <div class="sl-order-form__line-side" :class="lineSideClass">
        <!-- Helper -->
        <div class="sl-order-form__line-band-wrapper">
            <div class="sl-order-form__line-band-content" v-html="helper"></div>
        </div>

        <!-- Content Wrapper -->
        <div
            class="sl-order-form__line-content-wrapper"
            :tabindex="(parameter.is_enabled ? tabIndex : false)"
            :class="{ 'sl-order-form__line-content-wrapper--error': error, 'cursor-pointer': parameter.is_enabled }"
            @click="toggleValue()"
            @keypress.enter="toggleValue()"
            @keypress.space.prevent="toggleValue()"
        >
            <!-- Prefix -->
            <div v-if="parameter.prefix !== null" class="sl-order-form__prefix-wrapper">
                <div class="sl-order-form__prefix" v-html="parameter.prefix"></div>
            </div>

            <!-- Field Wrapper -->
            <div class="sl-order-form__field-wrapper">
                <!-- Field -->
                <mdi-icon
                    :icon="tempValue ? 'mdiCheck' : 'mdiCheckboxBlankOutline'"
                    class="sl-checkbox__check-icon"
                    :class="{ 'sl-checkbox--checked': tempValue }"
                />
            </div>

            <!-- Suffix -->
            <div v-if="parameter.suffix !== null" class="sl-order-form__suffix-wrapper">
                <div class="sl-order-form__suffix" v-html="parameter.suffix"></div>
            </div>

            <!-- Supplement -->
            <div v-if="supplement" class="sl-order-form__supplement-wrapper">
                <div class="sl-order-form__supplement">
                    {{ supplement }}
                </div>
            </div>

            <!-- Note -->
            <div v-if="parameter.note" class="sl-order-form__note-wrapper">
                <div class="sl-order-form__note" v-html="parameter.note"></div>
            </div>

            <!-- Error -->
            <div v-if="error" class="sl-order-form__error-wrapper">
                <div class="sl-order-form__error">
                    {{ error }}
                </div>
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import AbstractField from './AbstractField.vue';

export default
{
    name: 'CheckboxField',

    extends: AbstractField,

    emits: ['update:modelValue'],

    computed:
    {
        tempValue:
        {
            get()
            {
                return this.modelValue;
            },
            set(value)
            {
                this.$emit('update:modelValue', value);
            },
        },

        helper()
        {
            return null;
        },

        lineSideClass()
        {
            return {
                [`sl-order-form__line-side-${this.side}`]: true,
                'sl-order-form__line-side--disabled':      !this.parameter.is_enabled,
            };
        },
    },

    methods:
    {
        toggleValue()
        {
            if(this.parameter.is_enabled)
            {
                this.tempValue = !this.tempValue;
            }
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-order-form__line-content-wrapper
{
    @apply outline-none;
}

.sl-checkbox__check-icon
{
    @apply text-gray-500;

    height: 22px;
    width: 22px;
}

.sl-checkbox--checked
{
    @apply text-primary-500;
}

.sl-order-form__line-side:not(.sl-order-form__line-side--disabled) .sl-order-form__line-content-wrapper:focus-visible .sl-checkbox__check-icon
{
    @apply ring-1 ring-primary-400;
}
</style>
