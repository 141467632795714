<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-article-grid">
        <template
            v-for="item in mergedArticles"
            :key="item.id"
        >
            <grouped-article-grid-item
                v-if="item.type === 'GROUP'"
                :group="item"
                :filter-badges="filterBadgesCallback?.(item) || []"
                :badges="badgesCallback?.(item) || []"
                :hide-unit-price="hideUnitPrice"
            />

            <article-grid-item
                v-else
                :article="item"
                :filter-badges="filterBadgesCallback?.(item) || []"
                :badges="badgesCallback?.(item) || []"
                :hide-unit-price="hideUnitPrice"
            />
        </template>

    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import ArticleGridItem from './ArticleGridItem.vue';
import GroupedArticleGridItem from './GroupedArticleGridItem.vue';

export default
{
    name: 'ArticlesGrid',

    components:
    {
        ArticleGridItem,
        GroupedArticleGridItem,
    },

    props:
    {
        articles:
        {
            type:     Array,
            required: true,
        },

        filterBadgesCallback:
        {
            type: [Function],
            default()
            {
                // nothing
            },
        },

        badgesCallback:
        {
            type: [Function],
            default()
            {
                // nothing
            },
        },

        hideUnitPrice:
        {
            type:    Boolean,
            default: false,
        },
    },

    computed:
    {
        mergedArticles()
        {
            const out = [];
            const groups = {};
            for(let i = 0; i < this.articles.length; i++)
            {
                const article = this.articles[i];
                const group = article.extra.display_group;

                if(!group)
                {
                    out.push(article);
                    continue;
                }

                if(typeof groups[group] === 'undefined')
                {
                    groups[group] = {
                        id:       group,
                        type:     'GROUP',
                        articles: [],
                    };
                    out.push(groups[group]);
                }

                groups[group].articles.push(article);
            }

            return out;
        },
    },

    created()
    {
        this.fetchImages();
    },

    methods:
    {
        fetchImages()
        {
            const data = {
                eid:   this.$store.state.account.cEntity.id,
                codes: this.articles.map(a => a.code),
            };

            this.$store.dispatch('catalog/fetchImagesByArticleCodes', data)
                .then(images =>
                {
                    // nothing
                })
                .catch(error =>
                {
                    // TODO: Show error
                });
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>

</style>
