<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="flex px-4">
        <!-- First Column: Lens Parameters -->
        <div class="sl-order-details-col">
            <div class="sl-order-details__title">
                {{ t('order_table.details.section.lens_parameters.title') }}
            </div>

            <!-- Prototype -->
            <div class="sl-order-details-line">
                <div class="sl-order-details-line__label">
                    -&nbsp;{{ t(`parameters.PROTOTYPE_CODE.label`) }}:
                </div>

                <div class="sl-order-details-line__value" :title="prototypeCode || prototypeCodeLegacy">
                    {{ prototypeLabel }}
                </div>
            </div>

            <div v-for="prop in article_data" :key="prop.parameterCode" class="sl-order-details-line">
                <div class="sl-order-details-line__label">
                    -&nbsp;{{ getLabelByParameterCode(prop.parameterCode) }}<span v-if="getUnitByParameterCode(prop.parameterCode)" class="sl-order-details-line__unit">[&nbsp;{{ getUnitByParameterCode(prop.parameterCode) }}&nbsp;]</span>:
                </div>

                <!-- eslint-disable-next-line vue/no-v-html -->
                <div v-if="prop.parameterValue !== null" class="sl-order-details-line__value" v-html="pf(prop.parameterValue, prop.parameterCode, 'value')"></div>

                <div v-else class="sl-order-details-line__value">
                    -
                </div>

                <div
                    v-if="getHelp(prop.parameterCode, prop.parameterValue)"
                    class="ml-1 text-primary-400 text-xs"
                >
                    ({{ getHelp(prop.parameterCode, prop.parameterValue) }})
                </div>
            </div>

            <!-- Quantity -->
            <div class="sl-order-details-line">
                <div class="sl-order-details-line__label">
                    -&nbsp;{{ t(`parameters.quantity.label`) }}:
                </div>

                <div class="sl-order-details-line__value">
                    {{ order.quantity }}
                </div>
            </div>

            <!-- Previous Order Reference -->
            <div v-if="order.previous_order_reference" class="sl-order-details-line">
                <div class="sl-order-details-line__label">
                    -&nbsp;{{ t(`order.previous_order_reference.label`) }}:
                </div>

                <div class="sl-order-details-line__value">
                    <span>{{ order.previous_order_reference }}</span>
                </div>
            </div>

            <!-- Previous Serial Number -->
            <div v-if="order.previous_order_reference" class="sl-order-details-line">
                <div class="sl-order-details-line__label">
                    -&nbsp;{{ t("order.previous_lens_sn.label") }}:
                </div>

                <div class="sl-order-details-line__value">
                    <lsn-loader-link
                        v-if="!previousSn"
                        :label="t('common.view_previous_sn')"
                        :is-loading="snLoading"
                        class="ml-2"
                        @click="loadPreviousSn"
                    />

                    <template v-else>
                        {{ previousSn }}
                    </template>
                </div>
            </div>
        </div>

        <!-- Second Column: Logistics / Return / Extra -->
        <div class="sl-order-details-col">
            <!-- Logistics  -->
            <div class="sl-order-details__title">
                {{ t('order_table.details.section.delivery.title') }}
            </div>

            <!-- Delivery Address -->
            <div v-if="deliveryAddress" class="sl-order-details-line">
                <div class="sl-order-details-line__label">
                    {{ t('common.address.delivery.label') }}:
                </div>

                <!-- eslint-disable-next-line vue/no-v-html -->
                <div class="sl-order-details-line__address" v-html="f(deliveryAddress, 'address')"></div>
            </div>

            <!-- Delivery Options -->
            <div v-for="prop in logistics_data" :key="prop.parameterCode" class="sl-order-details-line">
                <div class="sl-order-details-line__label">
                    -&nbsp;{{ t(`parameters.${prop.parameterCode}.label`) }}:
                </div>

                <!-- eslint-disable-next-line vue/no-v-html -->
                <div class="sl-order-details-line__value" v-html="pf(prop.parameterValue, prop.parameterCode, 'value')"></div>
            </div>

            <!-- Return  -->
            <div class="sl-order-details__title">
                {{ t('order_table.details.section.return.title') }}
            </div>

            <div v-for="prop in return_data" :key="prop.parameterCode" class="sl-order-details-line">
                <div class="sl-order-details-line__label">
                    -&nbsp;{{ t(`parameters.${prop.parameterCode}.label`) }}:
                </div>

                <!-- eslint-disable-next-line vue/no-v-html -->
                <div class="sl-order-details-line__value" v-html="pf(prop.parameterValue, prop.parameterCode, 'value')"></div>
            </div>

            <!-- Complements  -->
            <div class="sl-order-details__title">
                {{ t('order_table.details.section.complements.title') }}
            </div>

            <div class="sl-order-details-line">
                <div class="sl-order-details-line__label">
                    {{ t('order_table.details.section.complements.placed_by_mean.label') }}:
                </div>

                <div class="sl-order-details-line__value">
                    {{ pf(order.extra.placed_by_mean, 'placed_by_mean', 'value') }}
                </div>
            </div>

            <div v-if="order.extra.ext_ref" class="sl-order-details-line">
                <div class="sl-order-details-line__label">
                    {{ t('parameters.ext_ref.label') }}:
                </div>

                <div class="sl-order-details-line__value">
                    {{ order.extra.ext_ref }}
                </div>
            </div>

            <!-- Delivery Note -->
            <div v-if="status.code === 'SHIPPED'" class="sl-order-details__section">
                <div class="sl-order-details__title">
                    {{ t('order_table.details.section.delivery_note.title') }}
                </div>

                <template v-if="deliveryNote">
                    <div class="sl-order-details-line">
                        <div class="sl-order-details-line__label">
                            {{ t('order_table.details.section.delivery_note.reference') }}:
                        </div>

                        <div class="sl-order-details-line__value">
                            {{ deliveryNote.reference_externe || deliveryNote.reference }}

                            <span>
                                |

                                <a
                                    v-tippy="{ arrow: true, theme: 'sl-light', animation : 'fade' }"
                                    :content="trackingStatus"
                                    :href="canTrack ? trackingUrl : '#!'"
                                    :target="canTrack ? '_blank' : '_self'"
                                    :class="{ 'lsn-table__link--disabled': !canTrack }"
                                    @click.stop
                                >
                                    {{ t('invoicing.delivery_notes.track') }}
                                </a>

                                |

                                <lsn-loader-link
                                    :label="t('common.download')"
                                    :is-loading="isFileDownloading"
                                    @click.stop="downloadFile"
                                />
                            </span>
                        </div>
                    </div>
                </template>

                <lsn-loader-link
                    v-else-if="deliveryNoteLoading"
                    label="load"
                    :is-loading="deliveryNoteLoading"
                    @click="loadDeliveryNote"
                />

                <div v-else class="sl-order-details-line">
                    <div class="sl-order-details-line__value">
                        &empty;
                    </div>
                </div>
            </div>

            <!-- Delete Order -->
            <div v-if="canDeleteOrder" class="sl-order-details__section">
                <div class="sl-order-details__title">
                    {{ t('order_table.details.section.delete_order.title') }}
                </div>

                <lsn-loader-link
                    v-tippy="{ arrow: true, theme: 'sl-light', animation : 'fade' }"
                    :content="deletionRequested ? t('order_table.details.request_deletion.already_sent') : ''"
                    :label="t('order_table.details.request_deletion.button_label')"
                    :is-loading="isDeletionRequestLoading"
                    class="text-xs"
                    :class="deletionRequestLinkClass"
                    @click="requestDeletionLink_OnClick"
                />
            </div>
        </div>

        <!-- Third Column: Price Details -->
        <div class="sl-order-details-col">
            <div class="sl-order-details__title">
                {{ t('order_table.details.section.price_details.title') }}
            </div>

            <div class="sl-order-price-line">
                <div class="sl-order-price-line__label">
                    {{ getLabel(order) }}
                </div>

                <div class="sl-order-price-line__price" :class="{ 'blur': !pricesVisible }">
                    <template v-if="!orderIsMoreExpensiveThanCatalogPrice">
                        {{ order.currency_code }}&nbsp;{{ f(order.price, 'decimal:2') }}
                    </template>
                </div>
            </div>

            <div v-for="supplement in order.supplements" :key="supplement.code" class="sl-order-price-line pl-4">
                <div class="sl-order-price-line__label">
                    +&nbsp;{{ supplement.article_label || supplement.article_code }}
                </div>

                <div class="sl-order-price-line__price" :class="{ 'blur': !pricesVisible }">
                    <template v-if="!orderIsMoreExpensiveThanCatalogPrice">
                        {{ supplement.currency_code }}&nbsp;{{ f(supplement.price, 'decimal:2') }}
                    </template>
                </div>
            </div>
        </div>
    </div>

    <teleport to="body">
        <delete-order-confirmation-modal
            :is-open="showDeleteOrderConfirmationModal"
            @cancel="showDeleteOrderConfirmationModal = false"
            @remove="requestDeletion()"
        />
    </teleport>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { omit } from 'lodash-es';
import { useToast } from 'vue-toastification';
import axios from '@/axios';
import $const from '@/constants/constants';
import { useStore } from 'vuex';
import { pf, f } from '@/utils/vue-formatter';
import LsnLoaderLink from '@/components/LsnLoaderLink.vue';
import useOrderTableComposable from '@/composables/OrderTableComposable';
import useFileDownloader from '@/composables/FileDownloader';
import useDeliveryNoteComposable from '@/composables/DeliveryNoteComposable';
import DeleteOrderConfirmationModal from '@/views/order/DeleteOrderConfirmationModal.vue';


// ------------------------------------------------------------ PROPS

const props = defineProps({
    order:
    {
        type:     Object,
        required: true,
    },

    context:
    {
        type:    String,
        default: 'on-going',
    },
});


// ------------------------------------------------------------ DATA

const snLoading           = ref(false);
const previousSn          = ref('');

const deliveryNoteLoading = ref(false);
const deliveryNote        = ref(null);

const showDeleteOrderConfirmationModal = ref(false);
const isDeletionRequestLoading         = ref(false);


// ------------------------------------------------------------ COMPOSABLES

const { t } = useI18n({ useScope: 'global' });
const store = useStore();

// OrderTableComposable
const {
    getLabel,
    getLabelByParameterCode,
    getUnitByParameterCode,
    getHelp,
} = useOrderTableComposable(props);

// FileDownloader
const { isFileDownloading, downloadFile } = useFileDownloader(
    () =>
    {
        const eid = store.state.account.cEntity.id;
        const documentId = deliveryNote.value.id;

        return `${import.meta.env.VITE_API_LOCATION}/api/delivery-note/${eid}/${documentId}/pdf`;
    },
    () => (deliveryNote.value.reference_externe || deliveryNote.value.reference) + '.pdf'
);

// DeliveryNoteComposable
const {
    canTrack,
    trackingStatus,
    trackingUrl,
} = useDeliveryNoteComposable({ deliveryNote });


// ------------------------------------------------------------ COMPUTED

const prototypeCode = computed(() =>
{
    return props.order.article_data['PROTOTYPE_CODE'];
});

const prototypeCodeReference = computed(() =>
{
    return props.order.article_data['PROTOTYPE_CODE_REFERENCE'];
});

const prototypeCodeLegacy = computed(() =>
{
    return props.order.article_data['PROTOTYPE_CODE_LEGACY'];
});

const prototypeLabel = computed(() =>
{
    return store.state.prototypes.prototypes.find(p => p.code === prototypeCode.value)?.label
        || prototypeCode
        || prototypeCodeLegacy;
});

const article_data = computed(() =>
{
    // Remove some values
    const data = omit(props.order.article_data, [
        'LENS_SIDE',
        'PROTOTYPE_CODE',
        'PROTOTYPE_CODE_LEGACY',
        'PATIENT_ID',
        'PATIENT_REFERENCE1',
        'PROTOTYPE_VERSION',
    ]);

    // Order values
    return collection(data);
});

const logistics_data = computed(() =>
{
    return collection(props.order.logistics_data);
});

const return_data = computed(() =>
{
    return collection(props.order.return_data);
});

const deliveryAddress = computed(() =>
{
    if(props.order.delivery_to == props.order.order_by)
    {
        return cEntity.value.addresses.find(a => a.type === 'DELIVERY') || null;
    }

    return null;
});

const cEntity = computed(() =>
{
    return store.state.account.cEntity;
});

const status = computed(() =>
{
    return [].concat(props.order.status).pop(); // last status
});

const orderIsMoreExpensiveThanCatalogPrice = computed(() =>
{
    if(props.order.special_price === null)
        return false;

    return props.order.special_price > props.order.catalog_total;
});

const canDeleteOrder = computed(() =>
{
    const isNotOrderConfirmation = props.context !== 'order-confirmation';
    const isNotShippedOrWaitingForShipping = ![
        $const.orders.status.WAITING_FOR_SHIPPING,
        $const.orders.status.SHIPPED,
    ].includes(status.value.code);

    return isNotOrderConfirmation && isNotShippedOrWaitingForShipping;
});

const deletionRequested = computed(() =>
{
    return props.order.extra.deletion_requested == '1';
});

const deletionRequestLinkClass = computed(() =>
{
    return {
        'lsn-loader-link--danger':   !deletionRequested.value,
        'lsn-loader-link--disabled': deletionRequested.value,
    };
});

const pricesVisible = computed(() => store.state.pricesVisible);


// ------------------------------------------------------------ MOUNTED

onMounted(() =>
{
    if(props.order.previous_order_reference)
    {
        loadPreviousSn();
    }

    if(status.value.code === $const.orders.status.SHIPPED)
    {
        loadDeliveryNote();
    }
});


// ------------------------------------------------------------ METHODS

function collection(data)
{
    const collection = [];

    // Order
    for(const key in data)
    {
        if(parseInt(store.getters['parameters/getProperty'](prototypeCode.value || prototypeCodeReference.value, key, 'has_access')))
        {
            collection.push({
                parameterCode:  key,
                parameterValue: data[key],
                parameterOrder: parseInt(store.getters['parameters/getProperty'](prototypeCode.value || prototypeCodeReference.value, key, 'sort_order')),
            });
        }
    }

    // Order values
    return collection.sort((a, b) => a.parameterOrder - b.parameterOrder);
}

function loadPreviousSn()
{
    if(snLoading.value)
    {
        return;
    }

    snLoading.value = true;
    const previousRef = props.order.previous_order_reference;
    axios.get(`/api/order/reference/${previousRef}`)
        .then(({ data: previousOrder }) =>
        {
            previousSn.value = previousOrder.article_data.LENS_SERIAL_NUMBER || '∅';
        })
        .catch(error =>
        {
            // todo: handle error
        })
        .then(() =>
        {
            snLoading.value = false;
        });
}

function loadDeliveryNote()
{
    if(deliveryNoteLoading.value)
    {
        return;
    }

    deliveryNoteLoading.value = true;
    const eid = cEntity.value.id;
    const orderReference = props.order.reference;
    axios.get(`/api/delivery-note/entity/${eid}/order/${orderReference}`)
        .then(response =>
        {
            deliveryNote.value = response.data;
        })
        .catch(error =>
        {
            // todo: handle error
        })
        .then(() =>
        {
            deliveryNoteLoading.value = false;
        });
}

function requestDeletionLink_OnClick()
{
    // Do not request deletion multiple times
    if(deletionRequested.value || isDeletionRequestLoading.value)
    {
        return;
    }

    showDeleteOrderConfirmationModal.value = true;
}

function requestDeletion()
{
    isDeletionRequestLoading.value = true;

    const reference = props.order.reference;
    axios.delete(`/api/order/reference/${reference}`)
        .then(response =>
        {
            isDeletionRequestLoading.value = false;
            showDeleteOrderConfirmationModal.value = false;
            store.dispatch('orders/setDeletionRequested', { orderId: props.order.id });
        })
        .catch(error =>
        {
            isDeletionRequestLoading.value = false;

            if(error.response.status !== 403)
            {
                throw error;
            }

            // The order cannot be deleted because it is already being shipped.
            // => Display a message to the user.
            useToast().error(t('order_table.details.request_deletion.forbidden'));
            showDeleteOrderConfirmationModal.value = false;
        });
}
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-order-details-col
{
    @apply w-1/3 pb-3;
}

.sl-order-details__title
{
    @apply text-gray-700 text-sm mt-4 mb-1;
}

.sl-order-details-line
{
    @apply flex flex-wrap;
}

.sl-order-details-line__label
{
    @apply text-xs text-gray-500;
}

.sl-order-details-line__unit
{
    @apply align-middle ml-1 mr-0.5 text-[8px] text-gray-400;
}

.sl-order-details-line__value
{
    @apply pl-2 text-xs text-primary-500;
}

.sl-order-details-line__address
{
    @apply w-full my-1 text-xs text-primary-500 leading-4;
}

.sl-order-price-line
{
    @apply flex mt-2 py-2 border-t border-gray-200;
}

.sl-order-price-line:last-child
{
    @apply border-b;
}

.sl-order-price-line__label
{
    @apply grow text-xs text-gray-500;
}

.sl-order-price-line__price
{
    width: 100px;
    @apply text-xs text-primary-500 text-right;
}
</style>
