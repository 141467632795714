<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <lsn-table-cell class="sl-invoice__date">
        {{ invoiceDate }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-invoice__reference">
        {{ invoice.reference }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-invoice__status">
        <div class="lsn-table__tag">
            {{ status }}
        </div>
    </lsn-table-cell>

    <lsn-table-cell class="sl-invoice__price" :class="{ 'blur': !pricesVisible }">
        {{ f(invoice.total_brut, 'thousand') }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-invoice__balance">
        {{ f(invoice.solde_pre_acquitte, 'thousand') }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-invoice__currency">
        {{ invoice.fk_reference_monnaie }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-invoice__due-date" :class="dueDateClass">
        {{ dueDate }}

        <template v-if="reminders">
            <br>
            <span class="text-xs">{{ reminders }}</span>
        </template>
    </lsn-table-cell>

    <lsn-table-cell class="sl-invoice__actions">
        <lsn-loader-link
            label="csv"
            :is-loading="isCsvDownloading"
            @click.stop="downloadCsv"
        />
        &nbsp;|&nbsp;
        <lsn-loader-link
            label="pdf"
            :is-loading="isFileDownloading"
            @click.stop="downloadFile"
        />
    </lsn-table-cell>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import LsnTableCell from '@/components/LsnTable/LsnTableCell.vue';
import LsnLoaderLink from '@/components/LsnLoaderLink.vue';
import { useStore } from 'vuex';
import { f } from '@/utils/vue-formatter';
import usePdfDownloader from '@/composables/FileDownloader';
import useCsvDownloader from '@/composables/CsvDownloader';


// ------------------------------------------------------------ COMPOSABLES

const { t } = useI18n({ useScope: 'global' });
const store = useStore();

const { isFileDownloading, downloadFile } = usePdfDownloader(
    () =>
    {
        const eid = store.state.account.cEntity.id;
        const documentId = props.invoice.id;

        return `${import.meta.env.VITE_API_LOCATION}/api/invoice/${eid}/${documentId}/pdf`;
    },
    () => props.invoice.reference + '.pdf'
);

const { isCsvDownloading, downloadCsv } = useCsvDownloader(
    () =>
    {
        const eid = store.state.account.cEntity.id;
        const documentId = props.invoice.id;

        return `${import.meta.env.VITE_API_LOCATION}/api/invoice/${eid}/${documentId}/csv`;
    },
    () => props.invoice.reference + '.csv'
);


// ------------------------------------------------------------ PROPS

const props = defineProps({
    invoice:
    {
        type:     Object,
        required: true,
    },
});


// ------------------------------------------------------------ COMPUTED

/**
 * The formatted date of the invoice.
 *
 * @return {string}
 */
const invoiceDate = computed(() =>
{
    return moment(props.invoice.fermeture).format('DD.MM.YYYY');
});

const lastPaymentDate = computed(() =>
{
    return moment(props.invoice.acquittement).format('DD.MM.YYYY');
});

/**
 * The formatted due date of the invoice.
 *
 * @return {string}
 */
const dueDate = computed(() =>
{
    return moment(props.invoice.delai_date_butoir).format('DD.MM.YYYY');
});

/**
 * Dynamic CSS class object for the "Due Date" column.
 *
 * @return {object}
 */
const dueDateClass = computed(() =>
{
    return {
        // The due date should be red if it has passed and the balance isn't zero
        'sl-invoice__due-date--passed': moment(props.invoice.delai_date_butoir).isBefore(new Date(), 'day') &&
            parseFloat(props.invoice.solde_pre_acquitte) != 0.00,

        // The due date should be narrower (vertically) if it has a second line
        'sl-invoice__due-date--has-reminder': !!reminders.value,
    };
});

/**
 * The localized status text of the invoice.
 *
 * @return {string} Either "Partial payment" or "Open".
 */
const status = computed(() =>
{
    if(props.invoice.solde_pre_acquitte != props.invoice.total_brut)
    {
        return t('invoicing.status.partial_payment');
    }

    return t('invoicing.status.open');
});

/**
 * How many reminders have already been issued (localized).
 *
 * @return {string} Empty string if none. "1st/2nd reminder" if 1 or 2. "3rd reminder" if 3 or more.
 */
const reminders = computed(() =>
{
    switch(props.invoice.reminders_count)
    {
        case 0:
            return '';

        case 1:
            return t('invoicing.reminder.first');

        case 2:
            return t('invoicing.reminder.second');

        case 3:
        default: // display "3rd reminder" even if there were more
            return t('invoicing.reminder.third');
    }
});

// /**
//  * The formatted date when the invoice was paid (acquittement).
//  *
//  * @return {string}
//  */
// const paidDate = computed(() =>
// {
//     if(!props.invoice.acquittement)
//     {
//         return '';
//     }

//     return moment(props.invoice.acquittement).format('DD.MM.YYYY');
// });

const pricesVisible = computed(() => store.state.pricesVisible);


// ------------------------------------------------------------ METHODS

function formatPrice(currency, amount)
{
    return currency + ' ' + format(amount, 'thousand');
}
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">
.sl-invoice__due-date--passed
{
    @apply text-red-600;
}

.sl-invoice__due-date--has-reminder
{
    @apply -my-2;
}
</style>
