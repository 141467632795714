<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <!-- Basket Line Date -->
    <lsn-table-cell class="sl-basket-line-cell__date">
        {{ formattedDate }}
    </lsn-table-cell>

    <!-- Article Details -->
    <lsn-table-cell class="sl-basket-line-cell__article">
        {{ getLabel(basketLine) }}
    </lsn-table-cell>

    <!-- External Reference -->
    <lsn-table-cell class="sl-basket-line-cell__ext-ref">
        <lsn-input
            ref="extRefField"
            :model-value="extRefField"
            :maxlength="18"
            :disabled="isUpdating"
            class="lsn-form__wrapper--padless"
            @update:model-value="updateExtRef($event, basketLine, 'extRefField')"
            @click.stop
        />
    </lsn-table-cell>

    <!-- Quantity -->
    <lsn-table-cell class="sl-basket-line-cell__quantity">
        <!-- Editable Field (if permitted) -->
        <lsn-input-number
            v-if="basketLine.article.status !== $const.articles.status.INACTIVE"
            ref="quantityField"
            :model-value="basketLine.quantity"
            placeholder="1"
            :min="1"
            :max="999"
            :step="1"
            :disabled="isUpdating"
            class="lsn-form__wrapper--padless"
            @update:model-value="updateQuantity($event, basketLine, 'quantityField')"
            @click.stop
        />

        <!-- Value Only -->
        <div v-else>
            {{ basketLine.quantity }}
        </div>
    </lsn-table-cell>

    <!-- Base Price -->
    <lsn-table-cell class="sl-basket-line-cell__base-price" :class="{ 'blur': !pricesVisible }">
        <template v-if="basePrice >= actualPrice">
            {{ formattedBasePrice }}
        </template>

        <template v-else>
            <!-- Pretend the base price is not lower than the actual price. -->
            {{ formattedActualPrice }}
        </template>
    </lsn-table-cell>

    <!-- Discount -->
    <lsn-table-cell
        class="sl-basket-line-cell__discount"
        :class="{
            'sl-basket-line-cell__discount--editable': $store.getters['account/can']('price:edit') && discount >= 0,
            'blur': !pricesVisible
        }"
    >
        <div v-if="$store.getters['account/can']('price:edit') && discount >= 0">
            <!-- Discount edition field -->
            <lsn-input-number
                ref="discountField"
                :model-value="discountField"
                placeholder="0"
                :max="100"
                :step="1"
                :disabled="isUpdating"
                class="lsn-form__wrapper--padless"
                @update:model-value="updateDiscount($event, basketLine, 'discountField')"
                @click.stop
            />
        </div>

        <div v-else>
            <template v-if="discount >= 0">
                {{ formattedDiscount }}
            </template>

            <template v-else>
                <!-- Pretend the discount cannot be negative. -->
                0%
            </template>
        </div>
    </lsn-table-cell>

    <!-- Price -->
    <lsn-table-cell class="sl-basket-line-cell__price" :class="{ 'blur': !pricesVisible }">
        <template v-if="$store.getters['account/can']('price:edit')">
            <!-- Price edition field -->
            <lsn-input-number
                ref="priceField"
                :model-value="priceField"
                placeholder="0.00"
                class="lsn-form__wrapper--padless"
                :min="0"
                :disabled="isUpdating"
                @update:model-value="updatePrice($event, basketLine, 'priceField')"
                @click.stop
            />
        </template>

        <template v-else>
            <span>{{ formattedActualPrice }}</span>
        </template>
    </lsn-table-cell>

    <!-- Action Buttons -->
    <lsn-table-cell class="sl-basket-line-cell__actions">
        <!-- Remove from basket -->
        <div class="lsn-table-btn__wrapper" @click.stop="deleteConfirmationOpen = true">
            <mdi-icon class="lsn-table-btn text-primary-400" icon="mdiDelete" />
        </div>
    </lsn-table-cell>

    <!-- Delete Basket Line Modal -->
    <teleport to="body">
        <lsn-modal :is-open="deleteConfirmationOpen">
            <div class="sl-prompt sl-prompt--danger">
                <div class="sl-prompt__contents">
                    <div class="sl-prompt__title">
                        {{ $t('order_table.delete_confirmation') }}
                    </div>

                    <div class="sl-prompt__text">
                        {{ $t('common.action_irreversible') }}
                    </div>
                </div>

                <div class="sl-prompt__actions">
                    <button class="sl-prompt__button sl-prompt__button--white" @click.stop="deleteConfirmationOpen = false">
                        {{ $t('common.cancel') }}
                    </button>

                    <button class="sl-prompt__button sl-prompt__button--red" @click.stop="remove()">
                        {{ $t('common.remove') }}
                    </button>
                </div>
            </div>
        </lsn-modal>
    </teleport>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import { debounce } from 'lodash-es';
import moment from 'moment';
import {
    actualPrice,
    basePrice,
    debounceDelay,
    discount,
    formattedActualPrice,
    formattedBasePrice,
    formattedDiscount,
    getLabel,
    matchEditableFieldValuesWith,
    price,
    quantity,
    updateDiscount,
    updateExtRef,
    updatePrice,
    updateQuantity,
} from '@/shared/BasketTableSharedObjects';
import LsnTableCell from '@/components/LsnTable/LsnTableCell.vue';

export default
{
    name: 'DefaultBasketLineSummary',

    components:
    {
        LsnTableCell,
    },

    props:
    {
        basketLine:
        {
            type:     Object,
            required: true,
        },
    },

    emits: [
        'remove',
        'edit',
    ],

    data()
    {
        return {
            deleteConfirmationOpen: false,
            isUpdating:             false,
            extRefField:            '',    // external reference displayed in the edition field
            priceField:             0.00,  // price displayed in the edition field
            discountField:          0.00,  // discount displayed in the edition field
        };
    },

    computed:
    {
        formattedDate()
        {
            return moment(this.basketLine.created_at).format('DD.MM.YYYY');
        },

        pricesVisible()
        {
            return this.$store.state.pricesVisible;
        },

        actualPrice,
        basePrice,
        discount,
        formattedActualPrice,
        formattedBasePrice,
        formattedDiscount,
        price,
        quantity,
    },

    watch:
    {
        basketLine()
        {
            this.matchEditableFieldValuesWith(this.basketLine);
        },
    },

    created()
    {
        // Debounce methods
        this.updateQuantity = debounce(this.updateQuantity, debounceDelay);
        this.updateDiscount = debounce(this.updateDiscount, debounceDelay);
        this.updatePrice    = debounce(this.updatePrice,    debounceDelay);
        this.updateExtRef   = debounce(this.updateExtRef,   debounceDelay);

        // Initialize editable field values
        this.matchEditableFieldValuesWith(this.basketLine);
    },

    methods:
    {
        remove()
        {
            this.$emit('remove', this.basketLine);
            this.deleteConfirmationOpen = false;
        },

        getLabel,
        matchEditableFieldValuesWith,
        updateDiscount,
        updateExtRef,
        updatePrice,
        updateQuantity,
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-basket-line-cell__date
{
    @apply text-gray-400 text-xs;
}

.sl-basket-line-subcell__article-label
{
    @apply text-xs text-gray-400 whitespace-nowrap text-ellipsis;
}

.sl-basket-line-cell__base-price
{
    @apply text-gray-400 text-sm text-center;
}

.sl-basket-line-cell__discount
{
    @apply text-gray-400 text-sm text-center;
}

.sl-basket-line-cell__price
{
    @apply text-gray-400 text-sm text-center;
}

// -------------------- Action Buttons

.sl-basket-line-cell__actions
{
    @apply flex items-center justify-end;
}

.lsn-table-btn__wrapper
{
    @apply flex items-center justify-center border border-primary-100 cursor-pointer;

    height: 34px;
    width: 34px;
    margin-left: 10px;
}

.lsn-table-btn__wrapper:hover
{
    @apply bg-primary-200;
}

.lsn-table-btn
{
    width: 20px;
    height: 20px;
}

// -------------------- Delete modal

.lsn-prompt
{
    max-width: 400px;
}

.lsn-prompt-content
{
    @apply p-8;
}
</style>
