/**
 * Convert a color from an RGB color space to an HSL color space.
 *
 * @param {number} r The red component in the range 0-255.
 * @param {number} g The green component in the range 0-255.
 * @param {number} b The blue component in the range 0-255.
 * @returns {{h: number, s: number, l: number}}
 */
export function rgb2hsl(r, g, b)
{
    // Make r, g, and b fractions of 1
    r /= 255;
    g /= 255;
    b /= 255;

    // Find greatest and smallest channel values
    const cmin = Math.min(r, g, b);
    const cmax = Math.max(r, g, b);
    const delta = cmax - cmin;
    let h = 0;
    let s = 0;
    let l = 0;

    // Calculate hue
    // No difference
    if(delta === 0)
        h = 0;
    // Red is max
    else if(cmax === r)
        h = ((g - b) / delta) % 6;
    // Green is max
    else if(cmax === g)
        h = (b - r) / delta + 2;
    // Blue is max
    else
        h = (r - g) / delta + 4;

    h = Math.round(h * 60);

    // Make negative hues positive behind 360°
    while(h < 0)
        h += 360;

    // Calculate lightness
    l = (cmax + cmin) / 2;

    // Calculate saturation
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

    // Multiply s and l by 100
    s *= 100;
    l *= 100;

    // Round s and l to 1 decimal
    s = Math.round(s * 10) / 10;
    l = Math.round(l * 10) / 10;

    return { h, s, l };
}
