<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->
<!-- eslint-disable vue/no-v-html -->

<template>
    <div class="sl-order-form__line-side" :class="lineSideClass">
        <!-- Helper -->
        <div class="sl-order-form__line-band-wrapper">
            <div class="sl-order-form__line-band-content" v-html="helper"></div>
        </div>

        <!-- Line Wrapper -->
        <div class="sl-order-form__line-content-wrapper" :class="{ 'sl-order-form__line-content-wrapper--error': error }">
            <!-- Prefix -->
            <div v-if="parameter.prefix !== null" class="sl-order-form__prefix-wrapper">
                <div class="sl-order-form__prefix" v-html="parameter.prefix"></div>
            </div>

            <!-- Field Wrapper -->
            <div class="sl-order-form__field-wrapper">
                <!-- Field -->
                <select
                    v-model="tempValue"
                    class="sl-order-form__select"
                    :name="name"
                    :tabindex="tabIndex"
                    :disabled="!parameter.is_enabled"
                    @focus="onFocus"
                    @blur="onBlur"
                >
                    <option
                        v-for="item in parameter.list_items"
                        :key="item.ovalue"
                        :value="item.ovalue"
                        :disabled="!item.is_enabled"
                    >
                        {{ item.label }}

                        <template v-if="item.help">
                            ({{ item.help }})
                        </template>
                    </option>
                </select>
            </div>

            <!-- Suffix -->
            <div v-if="parameter.suffix !== null" class="sl-order-form__suffix-wrapper">
                <div class="sl-order-form__suffix" v-html="parameter.suffix"></div>
            </div>

            <!-- Supplement -->
            <div v-if="supplement" class="sl-order-form__supplement-wrapper">
                <div class="sl-order-form__supplement">
                    {{ supplement }}
                </div>
            </div>

            <!-- Note -->
            <div v-if="parameter.note" class="sl-order-form__note-wrapper">
                <div class="sl-order-form__note" v-html="parameter.note"></div>
            </div>

            <!-- Error -->
            <div v-if="error" class="sl-order-form__error-wrapper">
                <div class="sl-order-form__error">
                    {{ error }}
                </div>
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import AbstractField from './AbstractField.vue';

export default
{
    name: 'SelectorField',

    extends: AbstractField,

    emits: ['update:modelValue'],

    computed:
    {
        tempValue:
        {
            get()
            {
                return this.modelValue;
            },

            set(value)
            {
                this.$emit('update:modelValue', value);
            },
        },

        helper()
        {
            return null;
        },

        lineSideClass()
        {
            return {
                [`sl-order-form__line-side-${this.side}`]: true,
                'sl-order-form__line-side--disabled':      !this.parameter.is_enabled,
            };
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-order-form__select:focus
{
    @apply outline-none border-primary-400;
}
</style>
