<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <component
        :is="componentFactory(parameter)"
        :key="parameter.code"
        v-model="pValue"
        :side="side"
        :index="index"
        :name="name"
        :parameter="parameter"
        :error="error"
    />
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import { upperFirst, camelCase } from 'lodash-es';
import CheckboxField             from '@/views/order/lenses/fields/CheckboxField.vue';
import InfoField                 from '@/views/order/lenses/fields/InfoField.vue';
import InputTextField            from '@/views/order/lenses/fields/InputTextField.vue';
import InputNumberField          from '@/views/order/lenses/fields/InputNumberField.vue';
import TextareaField             from '@/views/order/lenses/fields/TextareaField.vue';
import SelectorField             from '@/views/order/lenses/fields/SelectorField.vue';
import OpenSelectorField         from '@/views/order/lenses/fields/OpenSelectorField.vue';
import RadioButtonField          from '@/views/order/lenses/fields/RadioButtonField.vue';
import NoField                   from '@/views/order/lenses/fields/NoField.vue';
import DeleteButton              from '@/views/order/lenses/fields/custom/DeleteButton.vue';
import LensVariant               from '@/views/order/lenses/fields/custom/LensVariant2.vue';
import PatientReference1         from '@/views/order/lenses/fields/custom/PatientReference1.vue';
import SpecialPrice              from '@/views/order/lenses/fields/custom/SpecialPrice.vue';
import CustomerPrice             from '@/views/order/lenses/fields/custom/CustomerPrice.vue';
import OfgButton                 from '@/views/order/lenses/fields/custom/OfgButton.vue';
import OverRefractionCalculator  from '@/views/order/lenses/fields/custom/OverRefractionCalculator.vue';
import PlotGraphButton           from '@/views/order/lenses/fields/custom/PlotGraphButton.vue';
import ResetBackSurfaceButton    from '@/views/order/lenses/fields/custom/ResetBackSurfaceButton.vue';
import FormPrototype from '@/models/FormPrototype';

// Used for custom parameter form display.
const CUSTOM = {
    DeleteButton,
    LensVariant,
    PatientReference1,
    OfgButton,
    OverRefractionCalculator,
    PlotGraphButton,
    ResetBackSurfaceButton,
    SpecialPrice,
    CustomerPrice,
};

export default
{
    name: 'FieldFactory',

    props:
    {
        side:
        {
            type:     String,
            required: true,
        },
        index:
        {
            type:     Number,
            required: true,
        },
        parameter:
        {
            type:     Object,
            required: true,
        },
    },

    emits: ['templated'],

    computed:
    {
        /**
         * @returns {FormPrototype}
         */
        prototype()
        {
            return this.$store.getters['lensOrder/getPrototype'](this.side);
        },

        error()
        {
            return this.prototype.getErrorByParameterCode(this.parameter.code);
        },

        name()
        {
            return this.side + '_' + this.parameter.code;
        },

        pValue:
        {
            get()
            {
                return this.parameter.getValue();
            },

            set(value)
            {
                // Do not template if value has not changed.
                if(value !== null && value === this.parameter.getValue())
                {
                    console.log('NO VALUE CHANGE', this.parameter.code, value, this.parameter.getValue());
                    return;
                }

                console.log('SINGLE VALUE SET', this.side, this.parameter.code, value);

                this.$store.commit('lensOrder/setPrototypeValue', {
                    side:       this.side,
                    paramCode:  this.parameter.code,
                    paramValue: value,
                });

                this.$store.dispatch('lensOrder/templateAndValidate', {
                    side: this.side,
                    options:
                    {
                        only_present: true,
                        no_required:  true,
                    },
                }).then(() =>
                {
                    this.$emit('templated', { side: this.side });
                });
            },
        },
    },

    methods:
    {
        componentFactory(parameter)
        {
            const display = parameter.form_splitted_display || parameter.form_display;
            switch(display)
            {
                case 'NUMBER_INPUT':
                    return InputNumberField;

                case 'TEXT_INPUT':
                    return InputTextField;

                case 'LONG_TEXT_INPUT':
                    return TextareaField;

                case 'SELECTOR':
                    return SelectorField;

                case 'OPEN_SELECTOR':
                    return OpenSelectorField;

                case 'RADIO_BUTTON':
                    return RadioButtonField;

                case 'CHECKBOX':
                    return CheckboxField;

                case 'INFO':
                    return InfoField;

                case 'CUSTOM':
                    const field = upperFirst(camelCase(parameter.code));
                    return CUSTOM[field];

                case 'HIDDEN':
                    return NoField;

                default:
                    console.log('PARAMETER', parameter);
                    throw new Error(`Lens parameter splitted form display "${display}" does not exist for parameter with code "${parameter.code}" on the "${this.side}" side.`);
            }
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
</style>
