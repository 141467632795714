<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->
<!-- eslint-disable vue/no-v-html -->

<template>
    <div class="sl-order-form__line-side" :class="'sl-order-form__line-side-' + side">
        <!-- Helper -->
        <div class="sl-order-form__line-band-wrapper">
            <div class="sl-order-form__line-band-content" v-html="helper"></div>
        </div>

        <!-- Error Wrapper -->
        <div class="sl-order-form__line-content-wrapper" :class="{ 'sl-order-form__field-error-wrapper--error': error }">
            <!-- Field Wrapper -->
            <div
                class="sl-order-form__field-wrapper lsn-form__radio-item-wrapper"
                :tabindex="tabIndex"
                @keydown.up.prevent="selectPrevious()"
                @keydown.left.prevent="selectPrevious()"
                @keydown.right.prevent="selectNext()"
                @keydown.down.prevent="selectNext()"
            >
                <!-- Field -->
                <div
                    v-for="item in options"
                    :key="item.code"
                    class="lsn-form__radio-item"
                    :class="{ 'lsn-form__radio-item--selected': item.code === prototypeCode }"
                    @click="onPrototypeCodeChange(item.code)"
                >
                    <!-- Label -->
                    <div v-html="item.label"></div>

                    <!-- Help -->
                    <div
                        v-if="item.help"
                        v-tippy="{ animation : 'fade' }"
                        class="lsn-form__radio-item-help"
                        :content="item.help"
                    >
                        <mdi-icon icon="mdiInformationOutline" />
                    </div>
                </div>
            </div>

            <!-- Note -->
            <div v-if="parameter.note" class="sl-order-form__note-wrapper">
                <div class="sl-order-form__note">
                    {{ parameter.note }}
                </div>
            </div>

            <!-- Error -->
            <div v-if="error" class="sl-order-form__error-wrapper">
                <div class="sl-order-form__error">
                    {{ error }}
                </div>
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import FormPrototype from '@/models/FormPrototype';
import LensOrderUrlManager from '../../LensOrderUrlManager';
import RadioButtonField from '../RadioButtonField.vue';

export default
{
    name: 'SelectorField',

    extends: RadioButtonField,

    props:
    {
    },

    data()
    {
        return {
            prototypeCode: null,
        };
    },

    computed:
    {
        tabIndex()
        {
            return this.side === 'left' ? 2 : 1;
        },

        /**
         * @returns {FormPrototype}
         */
        prototype()
        {
            return this.$store.getters['lensOrder/getPrototype'](this.side);
        },

        options()
        {
            /** @type {FormPrototype[]} */
            const prototypes = this.$store.getters['prototypes/allByModelCode'](this.prototype.model_code);
            const options = [];

            prototypes.forEach(prototype =>
            {
                options.push({
                    label: prototype.variant_label || prototype.variant_code,
                    code:  prototype.code,
                });
            });

            return options;
        },

        helper()
        {
            return null;
        },
    },

    created()
    {
        this.$store.commit('lensOrder/setPrototypeValue', {
            side:       this.side,
            paramCode:  'LENS_VARIANT',
            paramValue: this.prototype.variant_code,
        });

        this.prototypeCode = this.prototype.code;
    },

    methods:
    {
        onPrototypeCodeChange(newPrototypeCode)
        {
            const newPrototype = this.$store.getters['prototypes/getByCode'](newPrototypeCode);
            this.$store.commit('lensOrder/setPrototypeValue', {
                side:       this.side,
                paramCode:  'LENS_VARIANT',
                paramValue: newPrototype.variant_code,
            });

            const lensOrderUrlManager = new LensOrderUrlManager(this.$route);
            lensOrderUrlManager.setPrototypeCode(this.side, newPrototypeCode);

            this.$router.replace({ path: lensOrderUrlManager.getPath() });
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
</style>
