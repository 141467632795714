<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div>
        <tippy class="sl-navbar__icon-item" @click.stop="isMenuOpen = true">
            <mdi-icon icon="mdiTranslate" />

            <template #content>
                {{ $t('languages_menu.label') }}
            </template>
        </tippy>

        <teleport to="body">
            <div v-if="isMenuOpen" class="sl-language-list__wrapper">
                <div v-click-outside="closeMenu" class="sl-language-list">
                    <div class="sl-language-list__close-btn-wrapper" @click="closeMenu()">
                        <mdi-icon icon="mdiClose" />
                    </div>

                    <div
                        v-for="(language, code) in languages"
                        :key="code"
                        class="sl-language-list__item"
                        @click="changeLanguage(code)"
                    >
                        {{ language }}
                    </div>
                </div>
            </div>
        </teleport>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import { delay } from 'lodash-es';

export default
{
    name: 'LanguagesMenu',

    data()
    {
        return {
            isMenuOpen: false,
        };
    },

    computed:
    {
        languages()
        {
            return {
                'en': 'English',
                'de': 'Deutsch',
                'fr': 'Français',
                'it': 'Italiano',
                'es': 'Español',
                'pt': 'Português',
                'pl': 'Polski',
                'nl': 'Nederlands',
                'uk': 'Українська',
                'ro': 'Româna',
            };
        },
    },

    methods:
    {
        closeMenu()
        {
            this.isMenuOpen = false;
        },

        changeLanguage(language)
        {
            const currentLanguage = this.$uconfig.get('language');

            if(currentLanguage !== language)
            {
                this.$uconfig.set('language', language);

                delay(() =>
                {
                    window.location.href = this.$route.fullPath.replace(/^\/[a-zA-Z]{2}\//i, `/${language}/`);
                }, 400);
            }
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-language-list__wrapper
{
    @apply fixed z-40 justify-center inset-0 flex bg-primary-10/75 backdrop-blur-sm;
}

.sl-language-list
{
    margin-top: 100px;
    @apply relative;
}

.sl-language-list__close-btn-wrapper
{
    right: -40px;
    top: -40px;
    @apply absolute cursor-pointer;
}

.sl-language-list__close-btn-wrapper .mdi-icon
{
    @apply w-[30px] h-[30px] text-primary-500;
}

.sl-language-list__item
{
    @apply py-3 px-20 m-3 border border-primary-200 bg-white text-center tracking-wide text-primary-500 cursor-pointer;
}

.sl-language-list__item:hover
{
    @apply bg-primary-100;
}
</style>
