<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <lsn-table-cell class="sl-invoice__date">
        {{ invoiceOpeningDate }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-invoice__reference">
        {{ invoice.reference }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-invoice__amount" :class="{ 'blur': !pricesVisible }">
        {{ $f(invoiceAmount, 'decimal:2|thousand') }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-invoice__currency" :class="{ 'blur': !pricesVisible }">
        {{ currency }}
    </lsn-table-cell>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import LsnTableCell from '@/components/LsnTable/LsnTableCell.vue';
import moment       from 'moment';

function sum(carry, note)
{
    return carry + parseFloat(note.total_brut);
};

export default
{
    name: 'OpenInvoiceLineSummary',

    components:
    {
        LsnTableCell,
    },

    props:
    {
        invoice:
        {
            type:     Object,
            required: true,
        },
    },

    computed:
    {
        /**
         * The formatted date of the invoice.
         * @return {string}
         */
        invoiceOpeningDate()
        {
            return moment(this.invoice.ouverture).format('DD.MM.YYYY');
        },

        invoiceAmount()
        {
            return [
                ...this.invoice.delivery_notes,
                ...this.invoice.credit_notes,
            ].reduce((carry, note) => carry + parseFloat(note.total_brut), 0);
        },

        currency()
        {
            return this.$store.state.catalog.articles[0]?.currency_code;
        },

        pricesVisible()
        {
            return this.$store.state.pricesVisible;
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">
</style>
