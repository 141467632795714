<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-order-form__line-side" :class="'sl-order-form__line-side-' + side">
        <!-- Helper -->
        <div class="sl-order-form__line-band-wrapper">
            <div class="sl-order-form__line-band-content"></div>
        </div>

        <!-- Error Wrapper -->
        <div class="sl-order-form__line-content-wrapper">
            <!-- Field Wrapper -->
            <div class="sl-order-form__field-wrapper">
                <!-- Field -->
                <div class="sl-order-form__graph">
                    <!-- Auto-Plot Toggle -->
                    <tippy
                        class="flex items-center gap-2"
                        :class="side === 'right' ? 'flex-row-reverse' : 'flex-row'"
                        :delay="500"
                    >
                        <lsn-switch
                            class="lsn-form__wrapper--padless"
                            :model-value="autoplot"
                            @update:model-value="toggleAutoplot"
                        />

                        <div
                            class="cursor-pointer flex items-center gap-1 text-sm select-none"
                            :class="[
                                autoplot ? 'text-primary-500' : 'text-gray-400',
                                side === 'right' ? 'flex-row-reverse' : 'flex-row',
                            ]"
                            @click="toggleAutoplot"
                        >
                            <div>
                                {{ $t('parameters.PLOT_GRAPH_BUTTON.auto_plot.label') }}
                            </div>

                        </div>

                        <template #content>
                            <div class="max-w-[200px] text-gray-500 text-center">
                                {{ $t('parameters.PLOT_GRAPH_BUTTON.auto_plot.help') }}
                            </div>
                        </template>
                    </tippy>

                    <!-- Actual Button -->
                    <div
                        class="lsn-btn lsn-btn--fitting"
                        :class="`lsn-btn--fitting-${cFittingId}`"
                        :tabindex="tabIndex"
                        @click.prevent="openOrRefreshGraph"
                        @keypress.enter="openOrRefreshGraph"
                    >
                        <mdi-icon icon="mdiChartBellCurve" class="inline-block w-5 h-5" />

                        <span class="inline-block ml-2">
                            {{ $t('parameters.PLOT_GRAPH_BUTTON.button_label') }}

                            <template v-if="graphOpen">
                                /
                                {{ $t('common.refresh') }}
                            </template>
                        </span>

                        <span v-if="fittings[side].length > 2" class="ml-1">
                            ({{ getLensLetter(cFittingId) }})
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <!-- Graph Window -->
        <teleport to="body">
            <lsn-sider :is-open="graphOpen" :side="side" class="sl-graph">
                <div class="sl-graph__content">
                    <!-- Title -->
                    <div
                        class="flex items-center mb-8 h-8"
                        :class="side === 'left' ? 'pl-12' : 'pr-12'"
                    >
                        <div class="text-primary-500 text-2xl font-thin">
                            {{ $t('plot_graph.window.title') }}
                        </div>
                    </div>

                    <!-- Close Buttton -->
                    <div>
                        <mdi-icon
                            icon="mdiClose"
                            class="sl-graph__close"
                            :class="`sl-graph__close--${side}`"
                            tabindex="0"
                            @click="graphOpen = false"
                        />
                    </div>

                    <div class="grid grid-cols-2 gap-2 mb-2">
                        <!-- Fitting Selector -->
                        <div class="sl-graph__fittings">
                            <template
                                v-for="(fitting, id) in fittings[side]"
                                :key="id"
                            >
                                <div
                                    v-if="fitting"
                                    class="cursor-pointer flex items-center rounded-sm select-none outline-none
                                        focus-visible:ring-1 focus-visible:ring-primary-500"
                                    tabindex="0"
                                    @click="toggleDrawing(side, id)"
                                    @keypress.enter="toggleDrawing(side, id)"
                                >
                                    <div>
                                        <mdi-icon
                                            v-if="selectedForDrawing.includes(id)"
                                            icon="mdiCheckboxMarked"
                                            class="w-5 h-5"
                                            :style="{ color: fittingColors[id] }"
                                        />

                                        <mdi-icon
                                            v-else
                                            icon="mdiCheckboxBlankOutline"
                                            class="w-5 h-5"
                                        />
                                    </div>

                                    <div class="ml-1 truncate">
                                        {{ $t('common.lens') }}
                                        {{ getLensLetter(id) }}
                                    </div>
                                </div>
                            </template>
                        </div>

                        <!-- Filters -->
                        <div class="sl-graph__filters">
                            <!-- Scale: Lens/Eye -->
                            <div>
                                <select
                                    v-model="scaleToEye"
                                    class="rounded border border-gray-300 px-2 py-1 bg-white select-none outline-none
                                        focus-visible:ring-1 focus-visible:ring-offset-1 focus-visible:ring-primary-500
                                        disabled:cursor-not-allowed disabled:opacity-60"
                                    :disabled="!hasCornea"
                                >
                                    <option :value="false">
                                        {{ $t('common.lens') }}
                                    </option>

                                    <option :value="true">
                                        {{ $t('common.eye') }}
                                    </option>
                                </select>
                            </div>

                            <div class="grow"></div>

                            <!-- Offset Filter -->
                            <div
                                class="flex items-center rounded-sm select-none outline-none
                                    focus-visible:ring-1 focus-visible:ring-offset-1 focus-visible:ring-primary-500"
                                :class="{
                                    'cursor-not-allowed': selectedForDrawing.length <= 1,
                                    'text-gray-400': selectedForDrawing.length <= 1,
                                    'cursor-pointer': selectedForDrawing.length > 1,
                                }"
                                tabindex="0"
                                @click="toggleBackSurfaceOffset"
                                @keypress.enter="toggleBackSurfaceOffset"
                            >
                                <div>
                                    <mdi-icon
                                        :icon="backSurfaceFilters.offset ? 'mdiCheck' : 'mdiCheckboxBlankOutline'"
                                        class="w-5 h-5"
                                        :class="{
                                            'text-gray-400': selectedForDrawing.length <= 1,
                                            'text-primary-500': selectedForDrawing.length > 1 && backSurfaceFilters.offset,
                                        }"
                                    />
                                </div>

                                <div class="ml-1 truncate">
                                    {{ $t('plot_graph.back_surface_filters.offset.label') }}
                                </div>
                            </div>

                            <!-- Center/Edge Focus Filter -->
                            <div v-if="!hasCornea">
                                <tippy placement="bottom" :delay="500">
                                    <select
                                        v-model="backSurfaceFilters.focusArea"
                                        class="rounded border border-gray-300 px-2 py-1 bg-white select-none outline-none
                                        focus-visible:ring-1 focus-visible:ring-offset-1 focus-visible:ring-primary-500
                                        disabled:cursor-not-allowed disabled:opacity-70"
                                        :disabled="selectedForDrawing.length <= 1 || hasCornea"
                                    >
                                        <option v-for="focusArea in ['CENTER', 'EDGE']" :key="focusArea" :value="focusArea">
                                            {{ $t(`plot_graph.back_surface_filters.focus_area.values.${focusArea}.label`) }}
                                        </option>
                                    </select>

                                    <template #content>
                                        <div class="py-2 max-w-[200px] text-gray-600 text-sm">
                                            <div class="flex items-center gap-2 mb-2 text-lg text-primary-500 font-thin">
                                                <mdi-icon icon="mdiInformationOutline" class="w-4 h-4" />

                                                <div>
                                                    {{ $t(`plot_graph.back_surface_filters.focus_area.label`) }}
                                                </div>
                                            </div>

                                            <p class="mb-2">
                                                <strong class="text-gray-700">
                                                    {{ $t(`plot_graph.back_surface_filters.focus_area.values.CENTER.label`) }}
                                                </strong>
                                                <br>
                                                {{ $t(`plot_graph.back_surface_filters.focus_area.values.CENTER.help`) }}
                                            </p>

                                            <p>
                                                <strong class="text-gray-700">
                                                    {{ $t(`plot_graph.back_surface_filters.focus_area.values.EDGE.label`) }}
                                                </strong>
                                                <br>
                                                {{ $t(`plot_graph.back_surface_filters.focus_area.values.EDGE.help`) }}
                                            </p>
                                        </div>
                                    </template>
                                </tippy>
                            </div>

                            <!-- Dilation Filter -->
                            <div v-if="hasCornea" class="flex items-center">
                                <div class="mr-1">
                                    {{ $t('plot_graph.back_surface_filters.dilation.label') }}
                                </div>

                                <div>
                                    <select
                                        v-model="backSurfaceFilters.dilationCoeff"
                                        class="rounded border border-gray-300 px-2 py-1 bg-white select-none outline-none
                                            focus-visible:ring-1 focus-visible:ring-offset-1 focus-visible:ring-primary-500"
                                        :class="{ 'text-primary-500': backSurfaceFilters.dilationCoeff !== 1 }"
                                    >
                                        <option v-for="coeff in dilationCoeffRange" :key="coeff" :value="coeff">
                                            {{ coeff.toFixed(1) }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Visualizations -->
                    <div class="sl-graph__visualizations relative">
                        <div v-if="loadingPoints" class="absolute top-0 left-0 w-full h-[240px] flex justify-center items-center italic">
                            <img src="/img/loader-5.gif" alt="">
                        </div>

                        <!-- Meridian Selector -->
                        <div class="mt-2">
                            <div class="flex justify-center items-center gap-4">
                                <button
                                    class="lsn-btn"
                                    :class="isMoving
                                        ? ['lsn-btn--gray', 'lsn-btn--disabled']
                                        : 'lsn-btn--primary'"
                                    :disabled="isMoving"
                                    @click="moveToAngle(flatAngle)"
                                >
                                    <mdi-icon
                                        icon="mdiCircleOffOutline"
                                        class="mr-2 w-4 h-4 -rotate-[45deg]"
                                    />

                                    {{ $t('plot_graph.flat_angle_button.label') }}
                                </button>

                                <button
                                    class="lsn-btn"
                                    :class="isMoving
                                        ? ['lsn-btn--gray', 'lsn-btn--disabled']
                                        : 'lsn-btn--primary'"
                                    :disabled="isMoving"
                                    @click="moveToAngle(steepAngle)"
                                >
                                    <mdi-icon
                                        icon="mdiCircleOffOutline"
                                        class="mr-2 w-4 h-4 rotate-[45deg]"
                                    />

                                    {{ $t('plot_graph.steep_angle_button.label') }}
                                </button>

                                <div
                                    class="flex justify-center items-center rounded border pl-0.5 pr-4 py-0.5 bg-white"
                                    :class="isMoving
                                        ? ['cursor-not-allowed', 'border-gray-300', 'text-gray-300']
                                        : ['cursor-pointer', 'border-primary-500', 'text-primary-500']"
                                    @click="$refs.angleInput.focus(); $refs.angleInput.select();"
                                >
                                    <div>
                                        <input
                                            ref="angleInput"
                                            v-model="angle"
                                            type="number"
                                            min="0"
                                            max="359"
                                            step="1"
                                            class="rounded pl-2 py-0.5 w-[60px] text-center outline-none"
                                            :class="isMoving ? 'cursor-not-allowed' : ''"
                                            :disabled="isMoving"
                                        >
                                    </div>

                                    <div class="mx-2 select-none">
                                        &harr;
                                    </div>

                                    <div class="select-none">
                                        {{ (angle + 180) % 360 }}°
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- 1D Lens & Cornea Graph -->
                        <div class="relative w-full h-[240px] resize overflow-hidden" :class="{ 'invisible': loadingPoints }">
                            <canvas id="graph-area" ref="graph-area"></canvas>
                        </div>

                        <!-- Data Tables -->
                        <template v-if="!loadingPoints">
                            <div
                                v-for="id in selectedForDrawing"
                                :key="id"
                                class="grid mt-2 rounded-md border border-l-4 border-gray-200
                                    bg-gray-50 text-gray-600 text-center text-sm overflow-hidden"
                                :class="zoneTableClass"
                                :style="{ borderLeftColor: fittingColors[id] }"
                            >
                                <!-- Lens/Fitting Name -->
                                <div class="bg-gray-100">
                                    <span class="text-primary-500">
                                        {{ $t('common.lens') }}
                                        {{ getLensLetter(id) }}
                                    </span>
                                    ⌀
                                    <span class="text-primary-500">
                                        {{ getOriginalMeridians(id, 'back')[0]?.maxDia().toFixed(1) }}
                                    </span>
                                    mm
                                </div>

                                <!-- Zone Names -->
                                <template
                                    v-for="zone in zones[id]"
                                    :key="zone"
                                >
                                    <div v-if="zone !== 'BEVEL'">
                                        {{ $t(`lens_zones.${zone}.label`) }}
                                    </div>
                                </template>

                                <div class="col-span-full"></div>

                                <template
                                    v-for="(meridian, j) in getOriginalMeridians(id, 'back')"
                                    :key="j"
                                >
                                    <!-- Meridian Angle & Lens/Fitting SAG -->
                                    <div
                                        class="grid gap-4 bg-gray-100 text-[11px]"
                                        :class="isAsymmetrical[id] || isQuadrant[id] ? 'grid-cols-2' : 'grid-cols-1'"
                                    >
                                        <div v-if="isAsymmetrical[id] || isQuadrant[id]" class="text-right">
                                            <template v-if="isAsymmetrical[id]">
                                                <span class="text-primary-500">
                                                    {{ $t(`plot_graph.angle_row.${j}.label`) }}
                                                </span>
                                            </template>

                                            <template v-else>
                                                <span class="text-primary-500">
                                                    {{ meridian.angle }}
                                                </span>°
                                            </template>
                                        </div>

                                        <div :class="isAsymmetrical[id] || isQuadrant[id] ? 'text-left' : 'text-center'">
                                            <span class="text-primary-500">
                                                {{ (meridian.maxSag() * 1000).toFixed(0) }}
                                            </span>
                                            µm
                                        </div>
                                    </div>

                                    <div
                                        v-for="zone in zones[id].filter(zone => zone !== 'BEVEL')"
                                        :key="zone"
                                        class="sl-graph__cell"
                                        :class="{ 'sl-graph__cell--last-row': j === getOriginalMeridians(id, 'back').length - 1 }"
                                    >
                                        <!-- Diameter -->
                                        <div
                                            v-if="!ANGLE_ZONES.includes(zone)"
                                            class="text-right"
                                        >
                                            ⌀
                                            <span class="text-primary-500">
                                                {{ meridian.zones[zone].dia.toFixed(1) }}
                                            </span>
                                            mm
                                        </div>

                                        <!-- Angle -->
                                        <div
                                            v-if="ANGLE_ZONES.includes(zone)"
                                            class="col-span-2 text-center"
                                        >
                                            <span class="text-primary-500">{{ getZoneAngle(meridian, zone).toFixed(0) }}</span>°
                                        </div>

                                        <!-- Eccentricity -->
                                        <div
                                            v-else-if="ECCENTRICITY_ZONES.includes(zone)"
                                            class="text-left"
                                        >
                                            e <span class="text-primary-500">{{ meridian.zones[zone].ecc.toFixed(2) }}</span>
                                        </div>

                                        <!-- Sagittal -->
                                        <div
                                            v-else
                                            class="text-left"
                                        >
                                            SAG
                                            <span class="text-primary-500">{{ (meridian.zones[zone].sag * 1000).toFixed(0) }}</span>
                                            µm
                                        </div>
                                    </div>

                                    <div class="col-span-full"></div>
                                </template>
                            </div>
                        </template>

                        <!-- 1D Tear Film -->
                        <div v-if="hasCornea" class="mt-4">
                            <div
                                v-if="!loadingPoints"
                            >
                                <div class="flex items-center gap-2 mb-2">
                                    <lsn-switch
                                        v-model="showTearFilm2"
                                        class="lsn-form__wrapper--padless"
                                    />

                                    <div
                                        class="cursor-pointer text-sm"
                                        :class="showTearFilm2 ? 'text-primary-500' : 'text-gray-400'"
                                        @click="showTearFilm2 = !showTearFilm2"
                                    >
                                        {{ $t('common.tear_film') }}
                                        {{ (angle + 90) % 360 }}°
                                    </div>
                                </div>

                                <TearFilm1D
                                    :cornea-points="corneaPoints"
                                    :back-surface-points="backSurfacePoints"
                                    :viewing-angle="angle"
                                    :show-angle2="showTearFilm2"
                                    :dtot="lensDiameter"
                                    :horizontal-scale="graphScales.x"
                                />
                            </div>

                            <div v-else class="flex justify-center items-center w-full h-[150px]">
                                <img src="/img/loader-5.gif" alt="">
                            </div>
                        </div>

                        <!-- 2D Cornea Graphs -->
                        <template v-if="hasCornea">
                            <div v-for="(id, i) in selectedForDrawing" :key="id" class="flex gap-2 mt-2">
                                <div v-if="loadingPoints" class="flex justify-center items-center w-full h-[150px]">
                                    <img src="/img/loader-5.gif" alt="">
                                </div>

                                <template v-else-if="params[id]?.DEFINE_CORNEA">
                                    <div v-for="j in [0, 1]" :key="j">
                                        <template v-if="i === 0 && !hasAllCorneasHiddenCol[j]">
                                            <!-- Visualization Selector -->
                                            <select
                                                v-model="corneaColoringMode[j]"
                                                class="mb-2 rounded border border-gray-300 px-2 py-1 w-full bg-white select-none outline-none
                                                    focus-visible:ring-1 focus-visible:ring-offset-1 focus-visible:ring-primary-500
                                                    disabled:cursor-not-allowed disabled:opacity-60"
                                            >
                                                <option value="AXIAL_RADIUS">
                                                    <!-- @i18n -->
                                                    Axial Radius
                                                </option>

                                                <option value="SAGITTAL">
                                                    <!-- @i18n -->
                                                    Sagittal
                                                </option>

                                                <option value="BEST_SPHERE">
                                                    <!-- @i18n -->
                                                    Best Sphere
                                                </option>

                                                <option value="TEAR_FILM">
                                                    <!-- @i18n -->
                                                    Tear Film
                                                </option>

                                                <!-- <option value="NONE">
                                                    Paint It Black!
                                                </option> -->
                                            </select>
                                        </template>

                                        <template v-if="!hiddenCorneas.includes(`${id}-${j}`)">
                                            <!-- Graph -->
                                            <Cornea2D
                                                :cornea-points="corneaPoints"
                                                :back-surface-points="backSurfacePoints[id]"
                                                :viewing-angle="angle"
                                                :coloring-mode="corneaColoringMode[j]"
                                                :params="params[id]"
                                                :show-pointer-data="false"
                                                :class="{ 'border-l-4': j === 0 }"
                                                :style="{ borderColor: fittingColors[id] }"
                                                @pointed="onCorneaPointed(id, $event)"
                                                @mouseenter="onCorneaEntered(id)"
                                                @mouseleave="onCorneaLeft"
                                                @minimized="onCorneaMinimized(id, j)"
                                                @maximized="onCorneaMaximized(id, j)"
                                            />
                                        </template>
                                    </div>

                                    <!-- Pointer Data -->
                                    <div
                                        v-if="hasCorneaPointerData[id] && !hasAllCorneasHiddenRow[id]"
                                        class="sl-graph__pointer-data"
                                        :style="{ marginTop: i === 0 ? '37px' : '0' }"
                                    >
                                        <div>
                                            <!-- "Constant" Pointer Data -->
                                            <div class="k-data-grid">
                                                <div>
                                                    Best Sphere
                                                </div>

                                                <div>
                                                    {{ corneaPointerData[id].bsRad.toFixed(2) }}&nbsp;mm
                                                </div>

                                                <div>
                                                    Apex
                                                </div>

                                                <div>
                                                    {{ (corneaPointerData[id].apex * 1000).toFixed(0) }}&nbsp;µm
                                                </div>
                                            </div>

                                            <!-- Pointed Data -->
                                            <div v-if="pointedCornea === id" class="k-data-grid">
                                                <div>
                                                    <!-- @i18n -->
                                                    Angle
                                                </div>

                                                <div>
                                                    {{ corneaPointerData[id].angle.toFixed(2) }}°
                                                </div>

                                                <div>
                                                    <!-- @i18n -->
                                                    DIA
                                                </div>

                                                <div>
                                                    {{ corneaPointerData[id].dia.toFixed(1) }}&nbsp;mm
                                                </div>

                                                <div>
                                                    <!-- @i18n -->
                                                    SAG
                                                </div>

                                                <div>
                                                    {{ (corneaPointerData[id].sag * 1000).toFixed(0) }}&nbsp;µm
                                                </div>

                                                <div>
                                                    <!-- @i18n -->
                                                    Axial Radius
                                                </div>

                                                <div>
                                                    {{ corneaPointerData[id].radius.toFixed(2) }}&nbsp;mm
                                                </div>

                                                <div>
                                                    <!-- @i18n -->
                                                    Axial Power
                                                </div>

                                                <div>
                                                    {{ corneaPointerData[id].power.toFixed(2) }}&nbsp;dpt
                                                </div>

                                                <div>
                                                    <!-- @i18n -->
                                                    ∆ Best Sphere
                                                </div>

                                                <div>
                                                    {{ corneaPointerData[id].bsSag > 0 ? '+' : '' }}{{ corneaPointerData[id].bsSag.toFixed(0) }}&nbsp;µm
                                                </div>

                                                <div>
                                                    <!-- @i18n -->
                                                    Tear Film
                                                </div>

                                                <div>
                                                    {{ corneaPointerData[id].tearFilm.toFixed(0) }}&nbsp;µm
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </template>
                    </div>
                </div>
            </lsn-sider>
        </teleport>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
/**
 * @typedef {import('@/composables/GraphComposable.js').Surface}    Surface
 * @typedef {import('@/composables/GraphComposable.js').GraphPoint} GraphPoint
 */

import { nextTick, shallowRef } from 'vue';
import Chart from 'chart.js/auto';
import { Interaction } from 'chart.js';
import { getRelativePosition } from 'chart.js/helpers';
import debounce from 'lodash-es/debounce';
import AbstractField from '../AbstractField.vue';
import axios from '@/axios';
import Cornea2D from '@/components/graphs/Cornea2D.vue';
import TearFilm1D from '@/components/graphs/TearFilm1D.vue';
import Numbers from '@/utils/Numbers';
import Objects from '@/utils/Objects';
import { deg2rad, exSRDbis, rad2deg, radiusDeltaSagEx } from '@/utils/Formulas';
import useFittingComposable from '@/composables/FittingComposable';
import useGraphComposable from '@/composables/GraphComposable';
import OffsetFilter from '@/views/graph/surface-filters/OffsetFilter';
import DilationFilter from '@/views/graph/surface-filters/DilationFilter';
import FormPrototype from '@/models/FormPrototype';
import GraphRequest from '@/models/GraphRequest';
import Meridian from '@/models/Meridian';
import AbstractSurfaceFilter from '@/views/graph/surface-filters/AbstractSurfaceFilter';
import Fitting from '@/models/Fitting';

const SURFACE_NAMES = ['cornea', 'back'];
const SCALE_PADDING_X = 0.25;
const SCALE_PADDING_Y = 0.25;
const MARKER_SIZE = 0.075;
const ANGLE_ZONES = [
    'SCLERAL',
];
const ECCENTRICITY_ZONES = [
    'PERIPHERAL',
    'CORNEAL',
    'ALIGNMENT',
];

/** The parameters which are required to calculate a cornea. */
const REQUIRED_CORNEA_PARAMS = [
    'EYE_CORNEA_GEOMETRY',
    'EYE_CORNEA_DIAMETER',
    'EYE_CORNEA_RADIUS',
    'EYE_CORNEA_ECCENTRICITY',
];

let oldSelection = [];

export default
{
    name: 'PlotGraphButton',

    components:
    {
        Cornea2D,
        TearFilm1D,
    },

    extends: AbstractField,

    setup()
    {
        // Data
        /** @type {{}|null} */
        const graph = null;

        /** @type {Surface[]} */
        const backSurfacePoints = shallowRef([]);

        /** @type {Surface} */
        const corneaPoints = shallowRef([]);

        // Composables
        const {
            getLensLetter,
            toggleDrawing,
        } = useFittingComposable();

        const {
            // Constants
            CORNEA_RESOLUTION,
            BACK_SURFACE_RESOLUTION,
            MIN_TEAR,

            // Methods
            getMeridian,
            getPreviousMeridian,
            getNextMeridian,
            getApexAlt360,
            getAllFittingColors,
        }  = useGraphComposable();

        return {
            // Constants
            ANGLE_ZONES,
            ECCENTRICITY_ZONES,

            CORNEA_RESOLUTION,
            BACK_SURFACE_RESOLUTION,
            MIN_TEAR,

            // Data
            /** @type {Chart} */
            graph,
            backSurfacePoints,
            corneaPoints,

            // Methods
            getLensLetter,
            toggleDrawing,

            getMeridian,
            getPreviousMeridian,
            getNextMeridian,
            getApexAlt360,
            getAllFittingColors,
        };
    },

    data()
    {
        return {
            loadingPoints: false,
            graphOpen:     false,

            /** The current viewing angle, in degrees. */
            angle: 0,

            /** Whether the viewing angle is currently being animated. */
            isMoving: false,

            fittingColors: [],

            /** Hack to refresh computed `shallowRef`. */
            refresh: 0,

            /** Hack to refresh computed `shallowRef`. */
            refreshCornea: 0,

            /** Hack to refresh computed chart config. */
            refreshGraphScales: 0,

            backSurfaceFilters:
            {
                /**
                 * How to align the lenses in the graph.
                 * @type {"CENTER"|"EDGE"}
                 */
                focusArea: 'CENTER',

                /** Whether the offset filter is enabled. */
                offset: false,

                dilationCoeff: 2.5,
            },

            /**
             * How wide to scale the graph:
             * - `false`: up to the diameter of the cornea or contact lens, whichever is larger (default);
             * - `true`: up to the diameter of the whole eye.
             */
            scaleToEye: false,

            /** @type {?GraphRequest} */
            lastCorneaRequest: null,

            showTearFilm2: false,

            /** @type {("AXIAL_RADIUS"|"SAGITTAL"|"BEST_SPHERE"|"TEAR_FILM")[]} */
            corneaColoringMode: ['AXIAL_RADIUS', 'TEAR_FILM'],

            corneaPointerData: [],
            pointedCornea:     0,
            hiddenCorneas:     [],
        };
    },

    computed:
    {
        /**
         * @returns {Fitting[]}
         */
        fittings()
        {
            return this.$store.state.lensOrder.fittings;
        },

        /**
         * The ID of the current fitting.
         *
         * @returns {number}
         */
        cFittingId()
        {
            return this.fittings.current[this.side];
        },

        /**
         * The current fitting.
         *
         * @returns {Fitting}
         */
        cFitting()
        {
            return this.fittings[this.side][this.cFittingId];
        },

        /**
         * The IDs selected for drawing.
         *
         * @returns {number[]}
         */
        selectedForDrawing()
        {
            return this.fittings.draw[this.side];
        },

        autoplot()
        {
            return this.fittings.autoplot[this.side];
        },

        /**
         * The curves to be drawn in the graph area for the fittings.
         *
         * @returns {{x: number, y: number}[][]}
         */
        backSurface()
        {
            this.refresh; // hack to force refresh shallowRef

            if(!this.backSurfacePoints.length)
            {
                return [];
            }

            const angle = this.angle;
            const out = [];
            for(const id of this.selectedForDrawing)
            {
                const letter = this.getLensLetter(id);
                const surface = this.backSurfacePoints[id];
                if(!surface?.length)
                {
                    out[id] = [];
                    console.warn(
                        `Skipped lens ${letter} because surface was not found`
                    );
                    continue;
                }

                const rightMeridian = this.getMeridian(surface, angle);
                const leftMeridian = this.getMeridian(surface, angle + 180);
                if(rightMeridian === null || leftMeridian === null)
                {
                    out[id] = [];
                    console.warn(
                        `Skipped lens ${letter} because meridian was not found`,
                        { rightMeridian, leftMeridian }
                    );
                    continue;
                }

                if(rightMeridian.resolution !== leftMeridian.resolution)
                {
                    out[id] = [];
                    console.warn(
                        `Skipped lens ${letter} because of resolution mismatch`,
                        { rightMeridian, leftMeridian }
                    );
                    continue;
                }

                out[id] = [
                    ...leftMeridian.points.map((sag, i) => ({
                        x: -leftMeridian.dia(i),
                        y: -sag,
                    })).reverse(),

                    ...rightMeridian.points.map((sag, i) => ({
                        x: rightMeridian.dia(i),
                        y: -sag,
                    })),
                ];
            }

            return out;
        },

        /**
         * The curve to be drawn in the graph area for the cornea.
         *
         * @returns {GraphPoint[]}
         */
        corneaSurface()
        {
            this.refreshCornea; // hack to force refresh shallowRef

            if(!this.corneaPoints.length)
            {
                return [];
            }

            const angle = this.angle;

            const rightMeridian = this.getMeridian(this.corneaPoints, angle);
            const leftMeridian = this.getMeridian(this.corneaPoints, angle + 180);
            if(rightMeridian === null || leftMeridian === null)
            {
                console.warn(`Skipped cornea because meridian was not found`,
                    { angle, rightMeridian, leftMeridian }
                );
                return [];
            }

            if(rightMeridian.resolution !== leftMeridian.resolution)
            {
                console.warn(`Skipped cornea because of resolution mismatch`,
                    { rightMeridian, leftMeridian }
                );
                return [];
            }

            return [
                ...leftMeridian.points.map((sag, i) => ({
                    x: -leftMeridian.dia(i),
                    y: -sag,
                })).reverse(),

                ...rightMeridian.points.map((sag, i) => ({
                    x: rightMeridian.dia(i),
                    y: -sag,
                })),
            ];
        },

        hasCornea()
        {
            // return this.params[this.cFittingId]?.DEFINE_CORNEA &&
            return this.params.some(params => params.DEFINE_CORNEA) &&
                this.corneaSurface.length > 0;
        },

        apex()
        {
            // Force refresh shallowRefs
            this.refresh;
            this.refreshCornea;

            const out = [];

            if(!this.hasCornea)
            {
                for(const id of this.selectedForDrawing)
                {
                    out[id] = 0;
                }

                return out;
            }

            for(const id of this.selectedForDrawing)
            {
                try
                {
                    out[id] = this.getApexAlt360(
                        this.corneaPoints,
                        this.backSurfacePoints[id]
                    );
                }
                catch(e)
                {
                    out[id] = 0;
                }
            }

            return out;
        },

        hasCorneaPointerData()
        {
            const out = [];
            for(const id of this.selectedForDrawing)
            {
                out[id] = Object.keys(
                    this.corneaPointerData[id] ?? {}
                ).length !== 0;
            }

            return out;
        },

        hasAllCorneasHiddenRow()
        {
            const out = [];
            for(const id of this.selectedForDrawing)
            {
                let hidden = 0;
                for(let i = 0; i < this.hiddenCorneas.length; i++)
                {
                    if(this.hiddenCorneas[i].startsWith(`${id}-`))
                    {
                        hidden++;
                    }

                    if(hidden >= 2)
                    {
                        break;
                    }
                }

                out[id] = hidden >= 2;
            }

            return out;
        },

        hasAllCorneasHiddenCol()
        {
            const out = [];
            for(let j = 0; j < 2; j++)
            {
                let hidden = 0;
                for(let i = 0; i < this.hiddenCorneas.length; i++)
                {
                    if(this.hiddenCorneas[i].endsWith(`-${j}`))
                    {
                        hidden++;
                    }

                    if(hidden >= 2)
                    {
                        break;
                    }
                }

                out[j] = hidden >= 2;
            }

            return out;
        },

        /**
         * The parameters as key-value pairs for the fittings.
         *
         * @returns {Record<string, any>[]}
         */
        params()
        {
            const out = [];
            for(let i = 0; i < this.selectedForDrawing.length; i++)
            {
                const id = this.selectedForDrawing[i];
                if(typeof id === 'undefined')
                {
                    out[id] = {};
                    continue;
                }

                const params = Array.from(
                    this.$store.getters['lensOrder/getPrototype'](this.side, id)
                        .getParameters()
                );
                out[id] = params.reduce(
                    (acc, [key, param]) => ({
                        ...acc,
                        [key]: param.getValue(),
                    }),
                    {}
                );
            }

            return out;
        },

        /**
         * The total diameter of the lens, for each fitting.
         *
         * @returns {number[]}
         */
        lensDiameter()
        {
            const out = [];
            for(const id of this.selectedForDrawing)
            {
                out[id] = this.params[id].LENS_DTOT;
            }

            return out;
        },

        /**
         * The cornea geometries for the fittings.
         *
         * @returns {("SYMMETRICAL"|"ASYMMETRICAL"|"QUADRANT")[]}
         */
        kGeometry()
        {
            const out = [];
            for(const id of this.selectedForDrawing)
            {
                out[id] = this.params[id].EYE_CORNEA_GEOMETRY;
            }

            return out;
        },

        /**
         * The names of the zones for each fitting.
         *
         * @returns {string[][]}
         */
        zones()
        {
            this.refresh; // hack to force refresh shallowRef

            const out = [];
            for(const id of this.selectedForDrawing)
            {
                out[id] = Object.keys(
                    this.backSurfacePoints[id]?.[0]?.zones ?? {}
                );
            }

            return out;
        },

        isAsymmetrical()
        {
            this.refresh; // hack to force refresh shallowRef

            const out = [];
            for(const id of this.selectedForDrawing)
            {
                out[id] = this.getOriginalMeridians(id, 'back').length === 2;
            }

            return out;
        },

        isQuadrant()
        {
            this.refresh; // hack to force refresh shallowRef

            const out = [];
            for(const id of this.selectedForDrawing)
            {
                out[id] = this.getOriginalMeridians(id, 'back').length === 4;
            }

            return out;
        },

        /**
         * The CSS class for the zone table, for each fitting.
         *
         * @returns {Object.<string, boolean>[]}
         */
        zoneTableClass()
        {
            this.refresh; // hack to force refresh shallowRef

            const out = [];
            for(const id of this.selectedForDrawing)
            {
                let nbZones = this.zones[id].length;
                if(this.zones[id].at(-1) !== 'BEVEL')
                {
                    nbZones++;
                }

                // Tailwind classes must be written in full for building
                out[id] = {
                    'hidden':      nbZones < 2,
                    'grid-cols-2': nbZones === 2,
                    'grid-cols-3': nbZones === 3,
                    'grid-cols-4': nbZones === 4,
                    'grid-cols-5': nbZones === 5,
                };
            }

            return out;
        },

        /**
         * The current original back surface meridians.
         *
         * @returns {Surface}
         */
        cOriginalBackSurfaceMeridians()
        {
            this.refresh; // hack to force refresh shallowRef

            const id = this.$store.state.lensOrder.fittings.current[this.side];
            const meridians = this.backSurfacePoints[id] ?? [];
            return meridians.filter(m => !m.calculated);
        },

        /**
         * The angle of the first meridian of the currently selected fitting.
         * Defaults to `0` if the meridian information is unavailable.
         *
         * @returns {number}
         */
        flatAngle()
        {
            return this.cOriginalBackSurfaceMeridians[0]?.angle ?? 0;
        },

        /**
         * The angle of the second meridian of the currently selected fitting.
         * Defaults to `flatAngle + 90` if there is no second meridian.
         *
         * @returns {number}
         */
        steepAngle()
        {
            return this.cOriginalBackSurfaceMeridians[1]?.angle
                ?? this.flatAngle + 90
                ?? 90;
        },

        /**
         * The options of the dilation filter.
         *
         * @returns {number[]}
         */
        dilationCoeffRange()
        {
            const step = 0.5;
            const out = [];
            for(let i = 2; i <= 8; i++)
            {
                out.push(i * step);
            }

            return out;
        },

        /**
         * The current scales configured for the graph.
         *
         * @returns {{x: {}, y: {}}}
         */
        graphScales()
        {
            this.refreshGraphScales;

            return this.graph?.options?.scales ?? {
                x: {},
                y: {},
            };
        },

        meridianAngles()
        {
            return this.corneaPoints.map(m => m.angle);
        },
    },

    watch:
    {
        backSurface:
        {
            handler: async function watchBackSurface()
            {
                if(this.graph === null)
                {
                    return;
                }

                // Rebuild back surface & markers datasets
                this.graph.data.datasets = this.graph.data.datasets.filter(
                    ds => !ds.code.startsWith('BACK_SURFACE_')
                );

                const markers = [];
                for(const id of this.selectedForDrawing)
                {
                    if(!this.backSurfacePoints[id]?.length)
                    {
                        continue;
                    }

                    // Build the zone's dataset
                    const data = this.applyBackSurfaceFilters(id);
                    this.graph.data.datasets.push({
                        code:             `BACK_SURFACE_${id}`,
                        lensId:           id,
                        label:            this.$t('common.lens') + ' ' + this.getLensLetter(id),
                        borderColor:      this.fittingColors[id],
                        backgroundColor:  this.fittingColors[id],
                        pointRadius:      0,
                        pointHitRadius:   1,
                        pointHoverRadius: 0,
                        showLine:         true,
                        borderJoinStyle:  'bevel',
                        borderWidth:      1,
                        data,
                    });

                    const positiveMeridian = this.getMeridian(
                        this.backSurfacePoints[id],
                        this.angle
                    );
                    const negativeMeridian = this.getMeridian(
                        this.backSurfacePoints[id],
                        this.angle + 180
                    );
                    const size = Math.max(
                        positiveMeridian.points.at(-1),
                        negativeMeridian.points.at(-1)
                    ) * MARKER_SIZE;

                    for(const z in positiveMeridian.zones)
                    {
                        const label = this.$t(`lens_zones.${z}.label`);
                        const { dia } = positiveMeridian.zones[z];
                        const { y: sag } = data.findLast(({ x }) => x <= dia);
                        markers.push(
                            ...this.getMarkerData(dia, sag, size, label)
                        );
                    }

                    for(const z in negativeMeridian.zones)
                    {
                        const label = this.$t(`lens_zones.${z}.label`);
                        const { dia } = negativeMeridian.zones[z];
                        const { y: sag } = data.find(({ x }) => -x <= dia);
                        markers.push(
                            ...this.getMarkerData(-dia, sag, size, label)
                        );
                    }
                }

                const markerColor = '#666';
                this.graph.data.datasets.unshift({
                    code:            'BACK_SURFACE_MARKERS',
                    borderColor:     markerColor,
                    backgroundColor: markerColor,
                    pointRadius:     0,
                    pointHitRadius:  2.5,
                    showLine:        true,
                    borderWidth:     1,
                    data:            markers,
                });

                // Update the display
                await this.updateDisplay();
            },
            deep: true,
        },

        corneaSurface:
        {
            handler: async function watchCorneaSurface()
            {
                if(this.graph === null)
                {
                    return;
                }

                // Rebuild cornea dataset
                this.graph.data.datasets = this.graph.data.datasets.filter(
                    ds => !ds.code.startsWith('CORNEA')
                );

                if(!this.corneaPoints.length)
                {
                    await this.updateDisplay();
                    return;
                }

                const corneaColor = '#654';

                // Build the zone's dataset
                this.graph.data.datasets.unshift({
                    code:             'CORNEA',
                    label:            this.$t('common.eye'),
                    borderColor:      corneaColor,
                    backgroundColor:  corneaColor,
                    pointRadius:      0,
                    pointHitRadius:   1,
                    pointHoverRadius: 0,
                    showLine:         true,
                    borderJoinStyle:  'bevel',
                    borderWidth:      1,
                    data:             this.corneaSurface,
                });

                // Add markers
                const markers = [];
                {
                    const label = this.$t('common.cornea');

                    // Negative meridian
                    const nMeridian = this.getMeridian(
                        this.corneaPoints,
                        this.angle + 180
                    );
                    const { dia: dia2, sag: sag2 } = nMeridian.zones.CORNEAL;
                    markers.push({ x: -dia2, y: -sag2, label });

                    // Positive meridian
                    const pMeridian = this.getMeridian(
                        this.corneaPoints,
                        this.angle
                    );
                    const { dia: dia1, sag: sag1 } = pMeridian.zones.CORNEAL;
                    markers.push({ x: dia1, y: -sag1, label });
                }

                this.graph.data.datasets.unshift({
                    code:            'CORNEA_MARKERS',
                    borderColor:     corneaColor,
                    backgroundColor: corneaColor,
                    pointRadius:     2.5,
                    pointHitRadius:  2.5,
                    showLine:        false,
                    borderWidth:     0,
                    data:            markers,
                });

                await this.updateDisplay();
            },
            deep: true,
        },

        selectedForDrawing:
        {
            handler: async function watchSelectedForDrawing(newSelection)
            {
                // Identify newly selected fittings
                const newIds = [];
                for(const newId of newSelection)
                {
                    if(!oldSelection.includes(newId))
                    {
                        newIds.push(newId);
                    }
                }
                newIds.sort();

                // Reset offset filter if necessary
                if(newSelection.length <= 1)
                {
                    this.backSurfaceFilters.offset = false;
                }

                // Recalculate newly selected fittings
                if(newIds.length && this.refresh > 0)
                {
                    await this.openOrRefreshGraph({
                        forceIncludeCurrentFitting: false,
                    });
                }

                // Update the old selection after handling
                oldSelection = newSelection.slice();
            },
            deep:      true,
            immediate: true,
        },

        backSurfaceFilters:
        {
            handler: function watchBackSurfaceFilters()
            {
                this.refresh++;
            },
            deep: true,
        },

        scaleToEye()
        {
            this.updateGraphScales();
        },
    },

    created()
    {
        this.fittingColors = this.getAllFittingColors();
        Interaction.modes.x1d = this.x1d;

        // Set the method to call for auto-plot
        this.$store.dispatch('lensOrder/setPlotMethod', {
            side:   this.side,
            method: () =>
            {
                // Re-plot if already open
                if(this.graphOpen)
                {
                    this.openOrRefreshGraph();
                }
            },
        });

        // this.updateGraphScales = debounce(this.updateGraphScales, 50, { maxWait: 200 });
    },

    updated()
    {
        if(!this.graph && this.$refs['graph-area'])
        {
            this.angle = 0;
            this.initGraph(this.$refs['graph-area']);
        }
        else if(this.graph && !this.$refs['graph-area'])
        {
            // Properly dispose of the graph
            this.graph.destroy();
            this.graph = null;
        }
    },

    methods:
    {
        async openOrRefreshGraph({ forceIncludeCurrentFitting = true } = {})
        {
            // Open the chart window
            this.graphOpen = true;

            if(this.loadingPoints)
            {
                return;
            }

            // Ensure current fitting is selected for drawing
            if(forceIncludeCurrentFitting)
            {
                await this.$store.dispatch('lensOrder/selectForDrawing', { side: this.side });
            }

            // Calculate graph data
            /** @type {GraphRequest[]} */
            const requests = [];
            const currCorneaRequest = this.getGraphRequests({ surface: 'cornea' }).at(-1);

            /** @type {FormPrototype} */
            const proto = this.$store.getters['lensOrder/getPrototype'](this.side);
            const eyeParamCodes = [...proto.getParameters().keys()].filter(key => key.startsWith('EYE_'));
            const hasCorneaParams = REQUIRED_CORNEA_PARAMS.every(requiredParamCode => eyeParamCodes.includes(requiredParamCode));
            if(hasCorneaParams)
            {
                // Calculate cornea if eye params have changed since last time
                let eyeParamsChanged = false;
                if(!this.lastCorneaRequest)
                {
                    // There wasn't a last request.
                    // That's a change.
                    eyeParamsChanged = true;
                }
                else
                {
                    // Check if any eye param has changed since the last request
                    const keyStartsWithEye = (val, key) => key.startsWith('EYE_');
                    const lastEyeParams = Objects.filter(this.lastCorneaRequest.values, keyStartsWithEye);
                    const currEyeParams = Objects.filter(currCorneaRequest.values, keyStartsWithEye);

                    if(Object.keys(lastEyeParams).length !== Object.keys(currEyeParams).length)
                    {
                        // There are more params in one request.
                        // That's a change.
                        eyeParamsChanged = true;
                    }
                    else
                    {
                        for(const key in currEyeParams)
                        {
                            if(!lastEyeParams.hasOwnProperty(key))
                            {
                                // The last request didn't have this param.
                                // That's a change.
                                eyeParamsChanged = true;
                                break;
                            }

                            if(lastEyeParams[key] !== currEyeParams[key])
                            {
                                // The last request had a different value for this param.
                                // That's a change.
                                eyeParamsChanged = true;
                                break;
                            }
                        }
                    }
                }

                if(eyeParamsChanged)
                {
                    requests.push(currCorneaRequest);
                }
            }

            // Calculate the back surface for each fitting
            requests.push(...this.getGraphRequests({ surface: 'back' }));

            try
            {
                await this.loadSurfacePoints(requests);
                this.lastCorneaRequest = currCorneaRequest;
            }
            catch(error)
            {
                this.lastCorneaRequest = null;
                if(requests.findIndex(r => r.surface === 'cornea') !== -1)
                {
                    this.corneaPoints = [];
                }

                if(error !== 'aborted')
                {
                    throw error; // @todo handle error
                }
            }
        },

        /**
         * Generate `GraphRequest` objects from the current fittings.
         *
         * @param {Object}          arg0
         * @param {number[]}        [arg0.ids]   The IDs of the fittings to get requests for.
         *                                       Defaults to those currently selected for drawing.
         *                                       This argument is not used if `surface` is `"cornea"`.
         * @param {"cornea"|"back"} arg0.surface The surface to get requests for.
         */
        getGraphRequests({ ids = [], surface })
        {
            if(!SURFACE_NAMES.includes(surface))
            {
                throw new TypeError(`getGraphRequests: unknown surface: ${surface}`);
            }

            const requests = [];
            switch(surface)
            {
                case 'cornea':
                    /** @type {FormPrototype} */
                    const proto = this.$store.getters['lensOrder/getPrototype'](this.side);
                    requests.push(new GraphRequest({
                        prototypeCode: proto.code,
                        surface:       'cornea',
                        values:        proto.getValues(),
                        options:
                        {
                            resolution: this.CORNEA_RESOLUTION,
                        },
                        handler: this.onCorneaMeridiansReceived,
                    }));
                    break;

                case 'back':
                    if(ids.length === 0)
                    {
                        ids = this.selectedForDrawing;
                    }

                    for(const id of ids)
                    {
                        /** @type {FormPrototype} */
                        const proto = this.$store.getters['lensOrder/getPrototype'](this.side, id);
                        requests.push(new GraphRequest({
                            fittingId:     id,
                            prototypeCode: proto.code,
                            surface:       'back',
                            values:        proto.getValues(),
                            options:
                            {
                                resolution: this.BACK_SURFACE_RESOLUTION,
                            },
                            handler: this.onBackSurfaceMeridiansReceived,
                        }));
                    }
                    break;
            }

            return requests;
        },

        closeGraph()
        {
            this.graphOpen = false;
        },

        initGraph(ctx)
        {
            // Initialize the graph instance
            if(!ctx)
            {
                console.warn('Graph area is unavailable!');
                return;
            }

            const graphOptions = {
                type: 'scatter',
                options:
                {
                    animation:           false,
                    responsive:          true,
                    maintainAspectRatio: false,
                    plugins:
                    {
                        legend:
                        {
                            display: false,
                        },
                        tooltip:
                        {
                            mode:      'x1d',
                            intersect: false,
                            filter(tooltipItem)
                            {
                                const dsCode = tooltipItem.dataset.code;
                                if(!dsCode)
                                {
                                    return false;
                                }

                                return !dsCode.endsWith('_MARKERS');
                            },
                            callbacks:
                            {
                                title: tooltipItems =>
                                {
                                    const x = tooltipItems.at(-1)?.raw.x ?? 0;
                                    const angle = x >= 0
                                        ? this.angle
                                        : (this.angle + 180) % 360;

                                    const dia = Math.abs(x);
                                    const kSag = this.getMeridian(this.corneaPoints, angle).sag(dia) * 1000;

                                    return `${angle}° · DIA: ${dia.toFixed(2)} mm`;
                                },

                                label: tooltipItem =>
                                {
                                    const dataset = this.graph?.data.datasets[tooltipItem.datasetIndex];
                                    if(!dataset?.code)
                                    {
                                        return;
                                    }

                                    // Handle markers datasets separately
                                    if(dataset.code.endsWith('_MARKERS'))
                                    {
                                        const label = dataset.data[tooltipItem.dataIndex].label;
                                        if(!label)
                                        {
                                            return;
                                        }

                                        return label;
                                    }

                                    const label = dataset.label;
                                    const x = Math.round(Math.abs(tooltipItem.raw.x) * 100) / 100;
                                    const angle = x >= 0
                                        ? this.angle
                                        : (this.angle + 180) % 360;
                                    const dia = Math.abs(x);
                                    const surface = dataset.lensId
                                        ? this.backSurfacePoints[dataset.lensId]
                                        : this.corneaPoints;
                                    const meridian = this.getMeridian(surface, angle);
                                    const y = Math.round(meridian.sag(dia) * 1000);
                                    const zone = meridian.zone(dia);
                                    let zoneName = this.$t(`lens_zones.${zone.code}.label`);
                                    if(!dataset.lensId)
                                    {
                                        zoneName = this.$t(`eye_zones.${zone.code}.label`);
                                    }

                                    return `${label} (${zoneName}): ${y} µm`;
                                },
                            },
                        },
                    },
                },
                data:
                {
                    datasets: [],
                },
            };
            this.graph = new Chart(ctx, graphOptions);
        },

        /**
         * Load multiple surfaces using `Promise.all()`.
         *
         * @param {GraphRequest[]} requests
         * @returns {Promise} The return value of `Promise.all()` or
         *                    a rejected promise with the value `"aborted"`.
         */
        async loadSurfacePoints(requests = [])
        {
            if(this.loadingPoints || requests.length === 0)
            {
                throw 'aborted';
            }

            this.loadingPoints = true;

            const promises = requests.map(r => new Promise((resolve, reject) =>
            {
                const url = '/api/prototype/calculate-points/:eid/:prototypeCode/:surfaceName'
                    .replace(':eid', this.$store.state.account.cEntity.id)
                    .replace(':prototypeCode', r.prototypeCode)
                    .replace(':surfaceName', r.surface);

                const data = { values: r.values };
                if(Object.keys(r.options).length)
                {
                    data.options = r.options;
                }

                axios.post(url, data)
                    .then(({ data }) =>
                    {
                        resolve(data);
                    })
                    .catch(error =>
                    {
                        reject(error);
                    });
            }));

            try
            {
                // Handle responses
                const responses = await Promise.all(promises);
                for(let i = 0; i < responses.length; i++)
                {
                    const request = requests[i];
                    const response = responses[i];

                    request.handleResponse(
                        request.fittingId,
                        response
                    );
                }

                return responses;
            }
            catch(error)
            {
                throw error; // todo: handle error
            }
            finally
            {
                this.loadingPoints = false;
            }
        },

        /**
         * Handler for when back-surface meridians are received from the server.
         *
         * @param {Object}  arg0
         * @param {number}  arg0.id   The ID of the fitting.
         * @param {Surface} arg0.data The meridians received.
         */
        onBackSurfaceMeridiansReceived({ id, data: meridians })
        {
            const surface = meridians.map(m => new Meridian(m));

            // Copy angles to their opposites to make full-width meridians
            for(let i = 0; i < meridians.length; i++)
            {
                const meridian = meridians[i];
                const oppositeAngle = (meridian.angle + 180) % 360;
                const oppositeMeridianIndex = surface.findIndex(
                    m => m.angle === oppositeAngle
                );
                if(oppositeMeridianIndex !== -1)
                {
                    // The opposite meridian exists; don't recalculate.
                    continue;
                }

                // The opposite meridian doesn't exist; mirror it.
                const oppositeMeridian = new Meridian({
                    ...meridian,
                    angle:      oppositeAngle,
                    calculated: true,
                });
                const meridianIndex = surface.findIndex(
                    m => m.angle === meridian.angle
                );
                surface.splice(meridianIndex + 1, 0, oppositeMeridian);
            }

            const oldFlatAngle = this.backSurfacePoints[id]?.[0]?.angle ?? 0;
            const newFlatAngle = meridians[0].angle;

            this.backSurfacePoints[id] = surface;

            // Force refresh computed on shallowRef
            this.refresh++;

            if(this.angle === oldFlatAngle && newFlatAngle !== oldFlatAngle)
            {
                this.$nextTick(() =>
                {
                    this.moveToAngle(newFlatAngle);
                });
            }
        },

        /**
         * Handler for when corneal meridians are received from the server.
         *
         * @param {Object}  arg0
         * @param {Surface} arg0.data The meridians received.
         */
        onCorneaMeridiansReceived({ data: meridians })
        {
            this.corneaPoints = meridians.map(m => new Meridian(m));

            // Force refresh computed on shallowRef
            this.refreshCornea++;
        },

        /**
         * Smoothly change the viewing angle.
         *
         * @param {Number} targetAngle The target angle in degrees (integer).
         * @param {Number} duration The duration, in milliseconds (integer).
         */
        async moveToAngle(targetAngle, duration = 500)
        {
            if(this.isMoving)
            {
                return;
            }

            targetAngle = (360 + (Math.round(targetAngle) % 360)) % 360;
            if(targetAngle === this.angle)
            {
                return;
            }

            duration = Math.trunc(Math.abs(duration));
            if(duration <= 0)
            {
                return;
            }

            this.isMoving = true;

            const diff = targetAngle - this.angle;
            const stepMs = 33; // ~30fps
            const stepAngle = Math.round(diff / (duration / stepMs)) || Math.sign(diff);

            const t0 = Date.now();
            while(this.angle !== targetAngle)
            {
                await new Promise(resolve =>
                {
                    setTimeout(resolve, stepMs);
                });

                this.angle += stepAngle;
                if(
                    (diff > 0) && (this.angle >= targetAngle) ||
                    (diff < 0) && (this.angle <= targetAngle)
                )
                {
                    this.angle = targetAngle;
                    this.isMoving = false;
                }
            }
        },

        /**
         * Calculate the angle of a zone in degrees.
         *
         * @param {Meridian} meridian
         * @param {string} zone
         * @returns {number}
         */
        getZoneAngle(meridian, zone)
        {
            const zones = Object.keys(meridian.zones);
            const i = zones.indexOf(zone);
            const { dia: dia1, sag: sag1 } = meridian.zones[zones[i - 1]];
            const { dia: dia2, sag: sag2 } = meridian.zones[zone];

            const deltaDia = dia2 - dia1;
            const deltaSag = (sag2 - sag1);

            return rad2deg(Math.atan(deltaSag / (deltaDia / 2)));
        },

        /**
         * Calculate the eccentricity of a zone.
         *
         * @param {Meridian} meridian
         * @param {string} zone
         * @returns {number}
         */
        getZoneEccentricity(meridian, zone)
        {
            // BUG: s1p should be the dia/sag of the first segment, which is
            // not necessarily the end of the optical zone! It is different on
            // the ScleraFlex lenses, for instance.
            // The simplest solution would be to have the 2nd segment of SCFL
            // lenses belong to the corneal zone rather than optical.
            const s1p = meridian.zones.OPTICAL;
            const r0 = radiusDeltaSagEx(s1p.sag, 0, 0, s1p.dia);

            const { dia, sag } = meridian.zones[zone];

            return exSRDbis(r0, sag, dia);
        },

        /**
         * Get the list of meridians that weren't calculated on the client.
         *
         * @param {number} id The fitting ID, or zero if N/A.
         * @param {"cornea"|"back"} surfaceName
         * @returns {Surface}
         */
        getOriginalMeridians(id, surfaceName)
        {
            const surface = {
                cornea: this.corneaPoints,
                back:   this.backSurfacePoints[id],
            }[surfaceName];

            return surface?.filter(m => !m.calculated) ?? [];
        },

        /**
         * Get the data for a dataset displaying vertical bars.
         *
         * @param {GraphPoint} point   The point on which the marker should be centered.
         * @param {number}     size    The size of the marker.
         * @param {?string}    [label] The label for the marker.
         * @returns {{x: ?number, y: ?number, label?: ?string}[]}
         */
        getMarkerData(x, y, size, label = null)
        {
            return [
                { x, y: y + (size / 2), label },
                { x, y, label },
                { x, y: y - (size / 2), label },
                { x: null, y: null }, // disconnect
            ];
        },

        /**
         * Apply transformations ("filters") to the back surface for display.
         *
         * @param {number} id The ID of the fitting, or zero if N/A.
         * @returns {GraphPoint[]} The transformed points.
         */
        applyBackSurfaceFilters(id)
        {
            const surface = this.backSurface[id];
            if(!surface?.length)
            {
                return [];
            }

            const angle = this.angle;
            const clSag = surface.at(-1).y;

            /** @type {AbstractSurfaceFilter[]} */
            const filters = [];

            // Position the lens onto the cornea.
            if(this.hasCornea)
            {
                filters.push(new OffsetFilter({
                    offset: this.apex[id] + this.MIN_TEAR,
                }));
            }

            // Allow offsetting lenses by the delta
            // between their respective SAGs and that of lens A.
            // Offset = maxSag(currentLens) - maxSag(lensA)
            if(this.backSurfaceFilters.focusArea === 'CENTER' && !this.hasCornea)
            {
                const getMaxSag = id => this.getMeridian(
                    this.backSurfacePoints[id],
                    angle
                ).maxSag();

                filters.push(new OffsetFilter({
                    offset: getMaxSag(id) - getMaxSag(1),
                }));
            }

            // Offset the lenses vertically.
            if(this.backSurfaceFilters.offset)
            {
                filters.push(new OffsetFilter({
                    // offset by 5% of the total sag
                    offset: Math.abs(0.05 * clSag * (id - 1)),
                }));
            }

            // // Exaggerate the difference in height of each sag.
            if(this.hasCornea && this.backSurfaceFilters.dilationCoeff !== 1)
            {
                const kMeridianPos = this.getMeridian(this.corneaPoints, angle);
                const kMeridianNeg = this.getMeridian(this.corneaPoints, angle + 180);

                const clMeridianPos = this.getMeridian(this.backSurfacePoints[id], angle);
                const clMeridianNeg = this.getMeridian(this.backSurfacePoints[id], angle + 180);
                const penultimateZone = this.zones[id].at(-2);
                const limitPos = clMeridianPos.zones[penultimateZone].dia;
                const limitNeg = clMeridianNeg.zones[penultimateZone].dia;

                filters.push(new DilationFilter({
                    coeff: this.backSurfaceFilters.dilationCoeff,
                    kMeridianPos,
                    kMeridianNeg,
                    limitPos,
                    limitNeg,
                }));
            }

            return filters.reduce((acc, f) => f.run(acc), surface);
        },

        toggleBackSurfaceOffset()
        {
            if(this.selectedForDrawing.length <= 1)
            {
                return;
            }

            this.backSurfaceFilters.offset = !this.backSurfaceFilters.offset;
        },

        /**
         * Update the graph scales using the latest data for the current angle.
         */
        updateGraphScales()
        {
            this.graph.options.scales = this.calculateGraphScales();
            this.graph.update();
            this.refreshGraphScales++;
        },

        /**
         * The scales configuration for the chart area.
         *
         * @returns {{}}
         */
        calculateGraphScales()
        {
            // Initialize `scales` object
            const scales = {
                x: { min: 0, max: 0 },
                y: { min: 0, max: 0 },
            };

            if(!this.backSurface.length)
            {
                return scales;
            }

            /** @type {{x: number, y: number}[]} */
            const allPoints = this.graph.data.datasets
                .filter(dataset => !dataset.code.endsWith('_MARKERS') && (!dataset.code.startsWith('CORNEA') || this.scaleToEye))
                .flatMap(dataset => dataset.data);

            const defaultMinMax = [
                0, // minX
                0, // minY
                0, // maxX
                0, // maxY
            ];

            // Use the size of the cornea (if any) as default bounds
            const corneaMarkers = this.graph.data.datasets.find(dataset => dataset.code === 'CORNEA_MARKERS')?.data ?? [];
            if(corneaMarkers.length !== 0)
            {
                const [marker1, marker2] = corneaMarkers;
                defaultMinMax[0] = marker1.x; // minX
                defaultMinMax[1] = marker1.y; // minY
                defaultMinMax[2] = marker2.x; // maxX
            }

            [
                scales.x.min,
                scales.y.min,
                scales.x.max,
                scales.y.max,
            ] = allPoints.reduce(([minX, minY, maxX, maxY], { x, y }) => [
                Math.min(minX, x),
                Math.min(minY, y),
                Math.max(maxX, x),
                Math.max(maxY, y),
            ], defaultMinMax);

            scales.x.min = Numbers.floorToMultiple(scales.x.min - SCALE_PADDING_X, SCALE_PADDING_Y);
            scales.y.min = Numbers.floorToMultiple(scales.y.min - (SCALE_PADDING_Y / 5), SCALE_PADDING_Y);
            scales.x.max = Numbers.ceilToMultiple(scales.x.max + SCALE_PADDING_X, SCALE_PADDING_X);
            scales.y.max = Numbers.ceilToMultiple(scales.y.max + (SCALE_PADDING_Y / 5), SCALE_PADDING_Y);

            scales.x.ticks = {
                beginAtZero: true,
                stepSize:    2,
                precision:   3,
                callback(value, index, tick)
                {
                    return Math.abs(value).toFixed(1);
                },
            };
            scales.y.ticks = {
                beginAtZero: true,
                stepSize:    SCALE_PADDING_Y,
                precision:   3,
                callback(value, index, tick)
                {
                    return value > 0 ? '' : (-value * 1000).toFixed(0);
                },
            };

            return scales;
        },

        /**
         * Custom interaction mode for Chart.JS.
         * Same as `'x'`, but limits results to one item per dataset.
         *
         * @function Interaction.modes.myCustomMode
         * @param {Chart}              chart              The chart we are returning items from.
         * @param {Event}              e                  The event we are finding things at.
         * @param {InteractionOptions} options            The Options to use.
         * @param {boolean}            [useFinalPosition] Whether to use the final element position (animation target).
         * @return {InteractionItem[]} The items that are found.
         * @see https://www.chartjs.org/docs/latest/configuration/interactions.html#custom-interaction-modes
         */
        x1d(chart, e, options, useFinalPosition)
        {
            const position = getRelativePosition(e, chart);

            const items = [];
            const datasets = {};
            Interaction.evaluateInteractionItems(chart, 'x', position, (element, datasetIndex, index) =>
            {
                if(element.inXRange(position.x, useFinalPosition) && !datasets[datasetIndex])
                {
                    datasets[datasetIndex] = true; // max 1 element per dataset
                    items.push({ element, datasetIndex, index });
                }
            });

            return items;
        },

        onCorneaPointed(id, pointerData)
        {
            this.corneaPointerData[id] = pointerData;
        },

        onCorneaEntered(id)
        {
            this.pointedCornea = id;
        },

        onCorneaLeft()
        {
            this.pointedCornea = 0;
        },

        onCorneaMinimized(kId, kJ)
        {
            // Show all corneas
            this.hiddenCorneas = [];
        },

        onCorneaMaximized(kId, kJ)
        {
            const serialized = `${kId}-${kJ}`;

            // Hide all corneas except the maximized one
            this.hiddenCorneas = [];
            for(const id of this.selectedForDrawing)
            {
                for(let j = 0; j < 2; j++)
                {
                    const curr = `${id}-${j}`;
                    if(curr !== serialized)
                    {
                        this.hiddenCorneas.push(curr);
                    }
                }
            }
        },

        async updateDisplay()
        {
            await nextTick();
            this.graph.update();
            this.updateGraphScales();
        },

        toggleAutoplot()
        {
            this.$store.dispatch('lensOrder/toggleAutoplot', this.side);
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-order-form__graph
{
    @apply flex items-center gap-2;
}

.sl-order-form__line-side-right .sl-order-form__graph
{
    @apply flex-row;
}

.sl-order-form__line-side-left .sl-order-form__graph
{
    @apply flex-row-reverse;
}

.sl-graph
{
    @apply h-screen overflow-hidden text-gray-500;

    // 256px = form label width on desktop
    // 8px   = approximative scrollbar width
    width: calc(50vw - (256px / 2) - 8px);
}

.sl-graph__content
{
    @apply relative p-8 w-full h-full overflow-auto;
}

.sl-graph__close
{
    @apply cursor-pointer fixed top-8 w-8 h-8 transition
        hover:text-primary-500;
}

.sl-graph__close--left
{
    @apply left-8;
}

.sl-graph__close--right
{
    @apply right-8;
}

// Fitting Selector
.sl-graph__fittings
{
    @apply flex justify-start items-center grow shrink gap-4 rounded-md p-2 bg-gray-100 truncate;
}

// Filters
.sl-graph__filters
{
    @apply flex justify-start items-center grow shrink gap-4 rounded-md p-2 bg-gray-100 text-sm;
}

// Button in the graph window
.sl-graph .lsn-btn
{
    @apply rounded border-primary-600 bg-primary-600 text-white
        hover:bg-primary-500 hover:border-primary-500
        focus-visible:bg-primary-500 focus-visible:border-primary-500
        active:bg-primary-700 active:border-primary-700;


    &:disabled
    {
        @apply cursor-not-allowed border-gray-300 bg-gray-300 text-gray-400;
    }
}

.sl-graph__cell
{
    @apply grid grid-cols-2 gap-4 border-b border-gray-100 bg-white text-[11px];
}

.sl-graph__cell--last-row
{
    @apply border-b-0;
}

// Graph Area
#graph-area
{
    @apply cursor-crosshair;
}

.sl-graph__pointer-data
{
    @apply grow;

}

.k-data-grid
{
    @apply grid grid-cols-[100px_80px] gap-x-2 gap-y-1;

    &:not(:first-child)
    {
        @apply mt-1;
    }

    > :nth-child(2n - 1)
    {
        @apply text-gray-600;
    }

    > :nth-child(2n)
    {
        @apply text-primary-500;
    }
}
</style>
