<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->
<!-- eslint-disable vue/no-v-html -->

<template>
    <div
        class="sl-order-form__line sl-order-form__line-section"
        :class="{
            'sl-order-form__line-section--open': isOpen,
            [`sl-order-form__line-section--${line.section}`]: true
        }"
    >
        <!-- Section Heading -->
        <div class="sl-order-form__section-heading cursor-pointer" @click="isOpen = !isOpen">
            <mdi-icon icon="mdiTriangle" />

            <div v-html="sectionLabel"></div>

            <mdi-icon icon="mdiTriangle" />
        </div>

        <!-- Section Content -->
        <div class="sl-order-form__section-content">
            <form-lines
                :section-parameters="sectionParameters"
                @templated="$emit('templated', $event)"
            />
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import { defineAsyncComponent } from 'vue';

export default
{
    name: 'SectionFormLine',

    components:
    {
        // Dynamic import to prevent recursion
        FormLines: defineAsyncComponent(() => import('./FormLines.vue')),
    },

    props:
    {
        index:
        {
            type:     Number,
            required: true,
        },

        line:
        {
            type:     Object,
            required: true,
        },
    },

    emits: ['templated'],

    data()
    {
        return {
            isOpen: true,
        };
    },

    computed:
    {
        sectionParameters()
        {
            if(!Array.isArray(this.line.rightParameter))
            {
                throw new TypeError('SectionFormLine: line.rightParameter must be an array');
            }
            if( !Array.isArray(this.line.leftParameter))
            {
                throw new TypeError('SectionFormLine: line.leftParameter must be an array');
            }

            return [
                ...this.line.rightParameter,
                ...this.line.leftParameter,
            ].map(p =>
            {
                // Make a copy of the parameters and remove their section code
                // to avoid infinite recursion.
                p = JSON.parse(JSON.stringify(p));
                p.section = null;

                return p;
            });
        },

        sectionLabel()
        {
            const tkey = `parameters.sections.${this.line.section}.label`;
            const label = this.$t(tkey);

            return (label !== tkey)
                ? label
                : this.line.section;
        },
    },

    methods:
    {
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
// Section Form Line
.sl-order-form__line-section
{
    @apply block border-none p-0 min-h-0;
}

// Heading
.sl-order-form__section-heading
{
    @apply flex justify-between items-center px-2 py-1 w-full min-h-[30px] text-primary-600 text-center text-sm select-none;

    > .mdi-icon
    {
        @apply w-3 h-3 text-gray-500 transition;

        &:first-child
        {
            @apply rotate-90;
        }

        &:last-child
        {
            @apply rotate-[270deg];
        }
    }
}

// Content
.sl-order-form__section-content
{
    @apply relative w-full;
}

// When the section is open
.sl-order-form__line-section.sl-order-form__line-section--open
{
    // Heading
    > .sl-order-form__section-heading
    {
        @apply bg-gray-200;

        > .mdi-icon
        {
            @apply text-primary-400 rotate-180;
        }
    }

    // Content
    > .sl-order-form__section-content
    {
    }
}

// When the section is closed
.sl-order-form__line-section:not(.sl-order-form__line-section--open)
{
    > .sl-order-form__section-heading
    {
        @apply bg-gray-300;
    }

    > .sl-order-form__section-content
    {
        @apply hidden;
    }
}

// Section-Specific Colorings

// Section: Cornea
.sl-order-form__line-section--CORNEA
{
    @apply bg-[#e0f8e0];

    // When the section is open
    &.sl-order-form__line-section--open
    {
        // Heading
        > .sl-order-form__section-heading
        {
            @apply bg-[#c0d8c0];
        }
    }

    &:deep(.sl-order-form__line-band-wrapper),
    &:deep(.sl-dimmer)
    {
        @apply bg-[#d0e8d0];
    }
}

// Section: Refraction
.sl-order-form__line-section--REFRACTION
{
    @apply bg-[#f0fff0];

    // When the section is open
    &.sl-order-form__line-section--open
    {
        // Heading
        > .sl-order-form__section-heading
        {
            @apply bg-[#d0dfd0];
        }
    }

    &:deep(.sl-order-form__line-band-wrapper),
    &:deep(.sl-dimmer)
    {
        @apply bg-[#e0efe0];
    }
}
</style>
