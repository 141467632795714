<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <tippy>
        <div class="sl-navbar__icon-item" @click.stop="onClick">
            <mdi-icon :icon="pricesVisible ? 'mdiEye' : 'mdiEyeClosed'" />
        </div>

        <template #content>
            <template v-if="pricesVisible">
                {{ t('prices_visibility.hide.label') }}
            </template>

            <template v-else>
                {{ t('prices_visibility.show.label') }}
            </template>
        </template>
    </tippy>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const { t } = useI18n({ useScope: 'global' });
const store = useStore();

const pricesVisible = computed(() => store.state.pricesVisible);

function onClick()
{
    store.dispatch('togglePricesVisibility');
}
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
</style>
