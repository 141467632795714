<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <lsn-table-cell class="sl-invoice__date">
        {{ invoiceDate }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-invoice__reference">
        {{ invoice.reference }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-invoice__paid-date">
        {{ paidDate }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-invoice__price" :class="{ 'blur': !pricesVisible }">
        {{ f(invoice.total_brut, 'thousand') }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-invoice__balance" :class="{ 'blur': !pricesVisible }">
        {{ f(invoice.solde_pre_acquitte, 'thousand') }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-invoice__currency" :class="{ 'blur': !pricesVisible }">
        {{ invoice.fk_reference_monnaie }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-invoice__due-date">
        {{ dueDate }}
    </lsn-table-cell>

    <lsn-table-cell class="sl-invoice__actions">
        <lsn-loader-link
            label="csv"
            :is-loading="isCsvDownloading"
            @click.stop="downloadCsv"
        />
        &nbsp;|&nbsp;
        <lsn-loader-link
            label="pdf"
            :is-loading="isFileDownloading"
            @click.stop="downloadFile"
        />
    </lsn-table-cell>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import LsnTableCell from '@/components/LsnTable/LsnTableCell.vue';
import LsnLoaderLink from '@/components/LsnLoaderLink.vue';
import { useStore } from 'vuex';
import { f } from '@/utils/vue-formatter';
import usePdfDownloader from '@/composables/FileDownloader';
import useCsvDownloader from '@/composables/CsvDownloader';


// ------------------------------------------------------------ COMPOSABLES

const { t } = useI18n({ useScope: 'global' });
const store = useStore();

const { isFileDownloading, downloadFile } = usePdfDownloader(
    () =>
    {
        const eid = store.state.account.cEntity.id;
        const documentId = props.invoice.id;

        return `${import.meta.env.VITE_API_LOCATION}/api/invoice/${eid}/${documentId}/pdf`;
    },
    () => props.invoice.reference + '.pdf'
);

const { isCsvDownloading, downloadCsv } = useCsvDownloader(
    () =>
    {
        const eid = store.state.account.cEntity.id;
        const documentId = props.invoice.id;

        return `${import.meta.env.VITE_API_LOCATION}/api/invoice/${eid}/${documentId}/csv`;
    },
    () => props.invoice.reference + '.csv'
);

// ------------------------------------------------------------ PROPS

const props = defineProps({
    invoice:
    {
        type:     Object,
        required: true,
    },
});


// ------------------------------------------------------------ COMPUTED

/**
 * The formatted date of the invoice.
 *
 * @return {string}
 */
const invoiceDate = computed(() =>
{
    return moment(props.invoice.fermeture).format('DD.MM.YYYY');
});

/**
 * The formatted due date of the invoice.
 *
 * @return {string}
 */
const dueDate = computed(() =>
{
    return moment(props.invoice.delai_date_butoir).format('DD.MM.YYYY');
});

/**
 * The formatted date when the invoice was paid (acquittement).
 *
 * @return {string}
 */
const paidDate = computed(() =>
{
    return moment(props.invoice.acquittement).format('DD.MM.YYYY');
});

const pricesVisible = computed(() => store.state.pricesVisible);
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss">
</style>
