<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->
<!-- eslint-disable vue/no-v-html -->

<template>
    <div class="sl-order-form__line-side" :class="lineSideClass">
        <!-- Helper -->
        <div class="sl-order-form__line-band-wrapper">
            <div class="sl-order-form__line-band-content" v-html="helper"></div>
        </div>

        <!-- Line Wrapper -->
        <div class="sl-order-form__line-content-wrapper" :class="{ 'sl-order-form__line-content-wrapper--error': error }">
            <!-- Prefix -->
            <div v-if="parameter.prefix !== null" class="sl-order-form__prefix-wrapper">
                <div class="sl-order-form__prefix" v-html="parameter.prefix"></div>
            </div>

            <!-- Field Wrapper -->
            <div class="sl-order-form__field-wrapper w-16">
                <!-- Field -->
                <input
                    ref="input"
                    v-model.lazy="tempValue"
                    class="sl-order-form__input"
                    type="text"
                    :name="name"
                    :tabindex="tabIndex"
                    autocomplete="off"
                    :disabled="!parameter.is_enabled"
                    @focus="onFocus"
                    @blur="onBlur"
                >
            </div>

            <!-- Suffix -->
            <div v-if="parameter.suffix !== null" class="sl-order-form__suffix-wrapper">
                <div class="sl-order-form__suffix" v-html="parameter.suffix"></div>
            </div>

            <!-- Supplement -->
            <div v-if="supplement" class="sl-order-form__supplement-wrapper">
                <div class="sl-order-form__supplement">
                    {{ supplement }}
                </div>
            </div>

            <!-- Note -->
            <div v-if="parameter.note" class="sl-order-form__note-wrapper">
                <div class="sl-order-form__note">
                    {{ parameter.note }}
                </div>
            </div>

            <!-- Error -->
            <div v-if="error" class="sl-order-form__error-wrapper">
                <div class="sl-order-form__error">
                    {{ error }}
                </div>
            </div>
        </div>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
import { toNumber }  from 'lodash-es';
import AbstractField from './AbstractField.vue';

export default
{
    name: 'InputNumberField',

    extends: AbstractField,

    emits: ['update:modelValue'],

    data()
    {
        return {
            isTouched: false,
            isFocused: false,
        };
    },

    computed:
    {
        helper()
        {
            const { min, max, step } = this.parameter;
            if(min === null || max === null || step === null)
            {
                return null;
            }

            return this.format(min)
                + '&nbsp;&rarr;&nbsp;'
                + this.format(max)
                + '<br>&plusmn;&nbsp;'
                + (this.format(step) + '').replace(/\+|-/g, '');
        },

        tempValue:
        {
            get()
            {
                if(this.isFocused || this.modelValue === null)
                {
                    return this.modelValue;
                }

                return this.formattedValue;
            },

            set(value)
            {
                if(value === null || value === this.value || value === '-' || value === '+')
                {
                    return;
                }

                if(typeof value === 'string')
                {
                    value = value.replace(',', '.');
                    value = value.replace(/[^0-9.-]/gi, '');
                }

                value = (value !== '') ? toNumber(value) : null;

                this.$emit('update:modelValue', value);
            },
        },

        lineSideClass()
        {
            return {
                [`sl-order-form__line-side-${this.side}`]: true,
                'sl-order-form__line-side--disabled':      !this.parameter.is_enabled,
            };
        },
    },

    methods:
    {
        onFocus()
        {
            this.isFocused = true;
            this.$nextTick(() =>
            {
                this.$refs.input.select();
            });
        },

        onBlur()
        {
            this.isFocused = false;
            this.isTouched = true;
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
// Highlight focused input
.sl-order-form__input
{
    &:focus
    {
        @apply outline-none border-primary-400;
    }

    &[disabled]
    {
        @apply bg-white;
    }
}
</style>
